import React from 'react';
import { Link } from 'react-router-dom';

const MenuDeroulantProfil = (props) => {
    return (
        <div className='menu-deroulant' onClick={props.close}>

        <ul>
            {(props.user.role === 'admin' || props.user.role === 'superAdmin') && 
                <li><Link to='/admin'>Administration</Link></li>}
            <li><Link to='/profil'>Profil</Link></li>
            {/* <li><Link to='/messages'>Messages</Link></li> */}
            <li><a onClick={props.deconnection}>Se déconnecter</a></li>

        </ul>
        </div>
    );
};

export default MenuDeroulantProfil;
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import LogoCompact from '../../icons/LogoCompact';
import MenuBurgerIcon from '../../icons/MenuBurgerIcon';

const TopMenu = (props) => {
    const { toggleMenu, openMenu } = useOutletContext();
    return (
        <div className={openMenu ? 'appBar' : 'appBar closed'}>
            <MenuBurgerIcon onClick={toggleMenu} />
        </div>
    );
};

export default TopMenu;
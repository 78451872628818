import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import TopMenu from '../menu/TopMenu';
import Allergenes from '../page-creation-produit/Allergenes';
import Categorie from '../page-creation-produit/Categorie';
import Conditionnement from '../page-creation-produit/Conditionnement';
import Conseils from '../page-creation-produit/Conseils';
import Introduction from '../page-creation-produit/Introduction';
import Photos from '../page-creation-produit/Photos';
import Presentation from '../page-creation-produit/Presentation';
import QuantiteCondi from '../page-creation-produit/QuantiteCondi';
import Recapitulatif from '../page-creation-produit/Recapitulatif';

const CreationProduit = (props) => {
    const { 
        newProduit, setNewProduit, 
        stepper, setStepper,
        errors, setErrors } = useOutletContext();


    /** automatically update state with input */
    const updateInput = (event) => {
        if (event.target.name === "statutPublication") {
            setNewProduit({
                ...newProduit,
                statutPublication: !newProduit.statutPublication
            })

        } else if (event.target.name === "description") {
            setNewProduit({
                ...newProduit,
                [event.target.name]: event.target.value
            })

        } else if (event.target.name === "newImages") {
            setNewProduit({
                ...newProduit,
                newImages: event.target.files
            })
        } else {

            setNewProduit({
                ...newProduit,
                [event.target.name]: event.target.value
            })
        }
        const newErrors = [...errors];
        const index = newErrors.indexOf(event.target.name);
        if (index > -1) {
            newErrors.splice(index, 1);
            setErrors(newErrors);
        }
    }

    const updateWhileSelecting = (inputName, valueSelected) => {
        const newErrors = [...errors];
        const index = newErrors.indexOf(inputName);
        if (index > -1) {
            newErrors.splice(index, 1);
            setErrors(newErrors);
        }
        if (inputName === "categorie") {
            setNewProduit({
                ...newProduit,
                [inputName]: valueSelected
            });
        }

        if (inputName === "conditionnement") {
            setNewProduit({
                ...newProduit,
                [inputName]: {
                    ...newProduit.conditionnement,
                    type: valueSelected
                }
            })
        }

        if (inputName.split('.')[0] === "pack" || inputName.split('.')[0] === "unit") {
            const type = inputName.split('.')[0];
            const singularOrPlural = inputName.split('.')[1];
            setNewProduit({
                ...newProduit,
                conditionnement: {
                    ...newProduit.conditionnement,
                    [type]: {
                        ...newProduit.conditionnement[type],
                        [singularOrPlural]: valueSelected
                    }
                }
            })
        }

        if (inputName === "quantiteCondi" || inputName === "allergenes") {
            if (newProduit[inputName].includes(valueSelected)) {
                setNewProduit({
                    ...newProduit,
                    [inputName]: newProduit[inputName].filter((value) => value !== valueSelected)
                })
            } else {
                setNewProduit({
                    ...newProduit,
                    [inputName]: [...newProduit[inputName], valueSelected].sort((a, b) => a - b)
                })
            }
        }
    }



    const handleNext = () => {
        setStepper(stepper + 1);
    }

    return (
        <div>
            <TopMenu />
            {stepper === 0 && <Introduction next={handleNext} editing={props.editing}/>}
            {stepper === 1 && <Presentation
                titre={newProduit.titre}
                description={newProduit.description}
                updateInput={updateInput}
                errors={errors}
            />}
            {stepper === 2 && <Photos
                editing={props.editing}
                images={props.editing ? newProduit.images : newProduit.newImages}
                updateInput={updateInput}
                errors={errors}
            />}
            {stepper === 3 && <Categorie
                categorie={newProduit.categorie}
                updateWhileSelecting={updateWhileSelecting}
                errors={errors}
            />}
            {stepper === 4 && <Conditionnement
                conditionnement={newProduit.conditionnement}
                updateWhileSelecting={updateWhileSelecting}
                errors={errors}
            />}
            {stepper === 5 && <QuantiteCondi
                quantiteCondi={newProduit.quantiteCondi}
                prix={newProduit.prix}
                updateInput={updateInput}
                updateWhileSelecting={updateWhileSelecting}
                errors={errors}
            />}
            {stepper === 6 && <Allergenes
                allergenes={newProduit.allergenes}
                updateWhileSelecting={updateWhileSelecting}
                errors={errors}
            />}
            {stepper === 7 && <Conseils
                conseilConservation={newProduit.conseilConservation}
                conseilDegustation={newProduit.conseilDegustation}
                updateInput={updateInput}
                errors={errors}
            />}
            {stepper === 8 && <Recapitulatif
                produit={newProduit}
                updateInput={updateInput}
                errors={errors}
            />}

        </div>
    );
};

export default CreationProduit;
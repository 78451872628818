import React from 'react';

const AProposCompact = () => {
    return (
        <article className="apropos">
                <div className='image'>

                    <img src={process.env.PUBLIC_URL + "/images/IMG-20220704-WA0003.jpg"} alt="" />
                </div>
            <div className='listArticles'>

                <div className='articles'>
                    <h2>Horaires d'ouverture</h2>
                    <article className='horaires'>
                        <div className='jour'>
                            <p>Mardi:</p>
                            <p>9h30 - 12h30</p>
                            <p>16h30 - 18h30</p>
                        </div>
                        <div className='jour'>
                            <p>Jeudi:</p>
                            <p>9h30 - 12h30</p>
                            <p>16h30 - 18h30</p>
                        </div>
                        <div className='jour'>
                            <p>Vendredi:</p>
                            <p>9h30 - 12h30</p>
                            <p>16h30 - 18h30</p>
                        </div>
                        <div className='jour'>
                            <p>Samedi:</p>
                            <p>9h30 - 12h30</p>
                            <p></p>
                        </div>
                        <div className='jour'>
                            <p>Dimanche:</p>
                            <p>9h30 - 12h30</p>
                            <p></p>
                        </div>
                    </article>
                </div>
                <div className='articles'>
                    <h2>Détails de préparation</h2>
                    <p>Je vous propose une sélection de pâtisseries artisanales réalisées exclusivement sur commande afin de vous garantir la fraîcheur et la saveur des produits. Pour cette raison, un délai d'au minimum 72h est nécessaire à la réalisation de vos desserts.</p>
                </div>
                <div className='articles'>
                    <h2>Réception des commandes</h2>
                    <p>La réception de vos commandes pourra se faire directement au point de retrait situé au :</p>
                    <p>100 route de Bertranot</p>
                    <p style={{marginBottom: '10px'}}>64370 Mesplède</p>
                    <p>La livraison à domicile est possible selon disponibilités. Merci de bien vouloir formuler votre demande en même temps que votre commande via le formulaire de contact ou par téléphone.</p>
                </div>
            </div>

        </article>
    );
};

export default AProposCompact;
import React from 'react';

const LogoFull = () => {
    return (
        <img 
        className='logo-full'
        src={process.env.PUBLIC_URL + '/images/logo/ML-logo_quadri_ssfd.png'} />
    );
};

export default LogoFull;
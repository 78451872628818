
import React, { useEffect, useState } from 'react';
import styles from '../../assets/scss/ChipsList.module.scss'


const SelectCategories = (props) => {
    


    const selectCategorie = (categorie) => {   
        if (props.options.categorie === categorie){
            return props.setOptions({ ...props.options, categorie: '' });
        }
        props.setOptions({ ...props.options, categorie: categorie });
    }


   

    return (
            <ul  className={styles.optionsList}>
                <li 
                        key={'tous'} 
                        className={props.options.categorie === '' ? styles.selected : styles.chip}
                        onClick={(e) => selectCategorie('')}
                        >Tout</li>
            
                {props.categories.map((categorie, index) => {
                    return <li 
                        key={index} 
                        className={props.options.categorie === categorie.nom ? styles.selected : styles.chip}
                        onClick={(e) => selectCategorie(categorie.nom)}
                        >{categorie.nom}</li>
                })}
            </ul>
       
    );
};

export default SelectCategories;
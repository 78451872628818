import { CircularProgress } from '@mui/material';
import { isMonday, isWednesday, isWithinInterval } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Connectivity } from '../../middlewares/Connectivity';
import Data from '../context/DataContext';
import InputDate from '../page-recap/InputDate';

const settings = require('../../config/settings.json');


const RecapCommande = (props) => {

    const { user } = useContext(Data);

    const [day, setDay] = useState(null);

    const [error, setError] = useState(null);

    const [pendingRequest, setPendingRequest] = useState(false);

    const [successfullOrder, setSuccessfullOrder] = useState({
        success: false,
        message: 'Votre réservation a bien été enregistrée, un email vous sera envoyé après validation de celle-ci.'
    })

    const horaires = ["9h30 - 12h30", "16h30 - 18h30"]



    /**
    * 
    * Function to add quantity to selected product in panier 
    */
    const addQuantite = (e) => {
        e.preventDefault();
        setError(null);
        if (props.panier[parseInt(e.target.name)].produitQuantiteAchat > 3) return
        const newPanier = [...props.panier];
        const produit = newPanier[parseInt(e.target.name)]
        produit.produitQuantiteAchat++
        produit.produitMontantTotal = produit.produitQuantiteAchat * produit.produitQuantiteCondi * produit.produitPrix
        props.setPanier([...newPanier]);
    }

    /**
     * 
     * Function to decrease quantity to selected product in panier 
     */
    const decreaseQuantite = (e) => {
        e.preventDefault();
        setError(null);
        const newPanier = [...props.panier];
        if (props.panier[parseInt(e.target.name)].produitQuantiteAchat < 2) return
        const produit = newPanier[parseInt(e.target.name)];
        produit.produitQuantiteAchat--;
        produit.produitMontantTotal = produit.produitQuantiteAchat * produit.produitQuantiteCondi * produit.produitPrix
        props.setPanier([...newPanier]);
    }

    /**
     * Function to delete item from panier
     */
    const deleteProduit = (index) => {
        setError(null);
        const newPanier = [...props.panier];
        newPanier.splice(parseInt(index), 1);
        props.setPanier([...newPanier]);
    }

    const dateRange = (day) => {
        return (
            isMonday(day) ||
            isWednesday(day) ||
            isWithinInterval(day, { start: new Date(2024, 5, 14), end: new Date(2024, 5, 16) }) ||
            isWithinInterval(day, { start: new Date(2024, 7, 12), end: new Date(2024, 7, 31) }) ||
            isWithinInterval(day, { start: new Date(2024, 9, 19), end: new Date(2024, 10, 4) })
        )

    }

    const reservNow = (e) => {
        e.preventDefault();
        if (!props.commande.retraitDate) return setError('Veuillez choisir une date valide.');
        if (dateRange(props.commande.retraitDate)) return setError('Veuillez choisir une date valide.');
        if (!props.commande.retraitHoraire) return setError('Veuillez choisir un horaire valide.');
        if (props.commande.nbProduits > 4) return setError('Vous avez trop de produits dans votre panier, veuillez contacter directement Mélanie pour passer une commande spéciale.');
        if (user.role === 'anonymous') return props.setShowConnexion(true);
        // if (!user.validationDate) return setError('Veuillez valider votre adresse email avant de commander.')
        setPendingRequest(true);
        const commande = {
            client: {
                id: user._id,
                nom: user.nom,
                prenom: user.prenom,
                adresse: user.adresse,
                telephone: user.telephone,
                email: user.email
            },
            panier: props.panier,
            montantTotal: props.commande.montantTotal,
            retraitDate: props.commande.retraitDate,
            retraitHoraire: props.commande.retraitHoraire
        }
        console.log(commande);
        try {

            Connectivity.createCommande(commande, user.token)
                .then(async (res) => {
                    const response = await res.json();
                    console.log(res.status);
                    if (res.status === 201) {
                        console.log(response.message)

                        props.setCommande({
                            montantTotal: null,
                            retraitDate: ''
                        })

                        props.setPanier([])

                        setSuccessfullOrder({
                            ...successfullOrder,
                            success: true
                        })
                    } else {
                        setError(response.message)
                    }
                    setPendingRequest(false);
                })
        } catch (err) {

            console.log(err);
            setError("Une erreur s'est produite, veuillez réessayer ultérieurement")
            setPendingRequest(false);

        }
    }

    const addHoraire = (e) => {
        e.preventDefault();
        setError(null);
        props.setCommande({
            ...props.commande,
            retraitHoraire: e.target.name
        })
    }



    useEffect(() => {
        if (props.panier.length > 0) {
            /** Every times panier is modified it updates total amount of commande */
            const totals = [];
            props.panier.forEach(element => {
                totals.push(element.produitMontantTotal)
            });
            const sommeTotal = totals.reduce(
                (previousValue, currentValue) => previousValue + currentValue
            )
            /** Every times panier is modified it updates total number of produits  */
            const nombreProduits = [];
            props.panier.forEach(element => {
                nombreProduits.push(element.produitQuantiteAchat)
            });
            const nbProdTotal = nombreProduits.reduce(
                (previousValue, currentValue) => previousValue + currentValue
            )
            props.setCommande({
                ...props.commande,
                nbProduits: nbProdTotal,
                montantTotal: sommeTotal
            })
        } else {
            /** If panier is empty, reset all values */
            props.setCommande({
                ...props.commande,
                nbProduits: null,
                montantTotal: null
            });
        }


    }, [props.panier])

    useEffect(() => {
        if (props.commande.retraitDate) {
            const today = new Date(props.commande.retraitDate).getDay();
            setDay(today);
            if (today === 0 || today === 6) {
                props.setCommande({
                    ...props.commande,
                    retraitHoraire: horaires[0]
                })
            }
        }

    }, [props.commande.retraitDate])

    return (
        <main className='page-recap'>
            {props.panier.length === 0 && !successfullOrder.success && <p className="empty">Votre panier est vide</p>}

            {props.panier.length === 0 && successfullOrder.success && <p className="empty">{successfullOrder.message}</p>}

            {props.panier.length > 0 && <section className="recap_commande">
                <article className="recap_panier">
                    <h2>Détails du panier :</h2>

                    <div>
                        <div className='list-items'>

                            {props.panier.map((produit, index) => {
                                return (<div key={index} className="item_panier">
                                    <div className='image'>
                                        <img src={settings.imgUrl + produit.produitImage} alt="" />
                                    </div>
                                    <div className='infos-produit'>
                                        <p className='montant-produit'>{produit.produitMontantTotal.toFixed(2)} €</p>
                                        {produit.produitTitre.length > 24 ?
                                            <p className='titre'>{produit.produitTitre.slice(0, 24)}...</p> :
                                            <p className='titre'>{produit.produitTitre}</p>

                                        }
                                        {produit.produitCondi?.type === 'unit' && <p>{produit.produitQuantiteCondi} {produit.produitQuantiteCondi > 1 ? produit.produitCondi?.unit?.plural : produit.produitCondi?.unit?.singular}</p>}
                                        {produit.produitCondi?.type === 'packOfUnit' && <p>{produit.produitQuantiteAchat > 1 ? produit.produitCondi?.pack?.plural + ' ' : produit.produitCondi?.pack?.singular} {produit.produitQuantiteCondi} {produit.produitQuantiteCondi > 1 ? produit.produitCondi?.unit?.plural : produit.produitCondi?.unit?.singular}</p>}

                                        <div className='select-quantity'>
                                            <button
                                                name={index}
                                                disabled={props.panier[index].produitQuantiteAchat < 2}
                                                onClick={decreaseQuantite} >-</button>
                                            <input readOnly value={produit.produitQuantiteAchat} />
                                            <button
                                                onClick={addQuantite}
                                                disabled={props.commande.nbProduits > 3}
                                                name={index}>+</button>
                                        </div>
                                        <div className='icon-delete' onClick={() => deleteProduit(index)}></div>
                                    </div>
                                </div>)
                            })}
                        </div>


                        {props.panier.length > 0 &&
                            <div className='total_panier'>
                                <h3>TOTAL :</h3>
                                <p>{parseFloat(props.commande.montantTotal).toFixed(2)} €</p>
                            </div>}
                    </div>

                </article>
                <article className="recap_reserver">
                    <h2>Choix du retrait</h2>
                    <p>Un délai minimum de <b>72h</b> est nécessaire pour préparer votre commande. Le <b>paiement</b> se fera au retrait de celle-ci.</p>
                    <InputDate
                        commande={props.commande}
                        setCommande={props.setCommande}
                    />
                    <p className={Boolean(error) ? 'error-message' : 'hidden'}>{error}</p>
                    {props.commande.retraitDate && <div className='time-buttons'>
                        <button
                            name={horaires[0]}
                            onClick={addHoraire}
                            className={props.commande.retraitHoraire === horaires[0] ? 'time selected' : 'time'}
                        >{horaires[0]}</button>
                        <button
                            name={horaires[1]}
                            onClick={addHoraire}
                            className={props.commande.retraitHoraire === horaires[1] ? 'time selected' : 'time'}
                            disabled={(day === 0 || day === 6)}
                        >{horaires[1]}</button>
                    </div>}
                    {!pendingRequest && <button className='reserver-button' onClick={reservNow}>RESERVER MAINTENANT</button>}
                    {pendingRequest && <button className='reserver-button' disabled><CircularProgress color='inherit' /></button>}
                    <p className='acceptCgv'>* En reservant votre commande, vous acceptez les <a onClick={props.cgv}>Conditions générales de vente</a></p>

                </article>
            </section>}
        </main>
    );
};

export default RecapCommande;
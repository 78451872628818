import React from 'react';

const RGPD = (props) => {
    return (
        <div className='fond-modal' onClick={props.close}>
            <div className='modal-mrc'>
                <div className='cross'></div>

                <h1>Politique de confidentialité et RGPD</h1>
                <p className='indent'>La présente politique de confidentialité définit et vous informe de la manière dont sont utilisées et protégées vos données personnelles, lorsque vous transmettez le cas échéant des données personnelles à partir de l'URL : www.ml-patisserie.fr (ci-après le « Site »).</p>
                <article>
                    <h2>1. Identité du responsable du traitement</h2>

                    <p>La responsable du traitement est Madame LEBESGUE Mélanie, dont les coordonnées sont :</p>
                    <blockquote>
                    <p>100 route de Bertranot</p>
                    <p>64370 Mesplède</p>
                    <p>Tel : 0685259242</p>
                    <p>contact@ml-patisserie.fr</p>
                    </blockquote>
                </article>
                <article>
                    <h2>2. Finalités du traitement</h2>
                <p className='indent'>Vos informations personnelles sont susceptibles d'être recueillies aux fins de vous fournir les informations ou services que vous avez demandé via l'utilisation du formulaire de contact du site, ou via la création d'un compte client vous permettant de passer commande à travers le site. Ces informations sont recueillies sous forme de connexion sécurisée, identifiable avec le mode « https » et pictogramme en forme de cadena dans la barre d'adresse.</p>
                </article>
                <article>
                    <h2>3. Destinataires</h2>
                <p className='indent'>Seule Madame LEBESGUE Mélanie est destinataire de vos informations personnelles. Celles-ci ne sont jamais transmises ou vendues à des tiers.</p>
                </article>
                <article>
                    <h2>4. Durée de conservation</h2>
                <p className='indent'>Vos informations personnelles sont conservées uniquement pour le temps correspondant à la finalité de la collecte telle qu'indiquée en 2 ci-dessus, et ne sauraient en tout état de cause dépasser 12 mois si aucuns contacts ou aucunes activités du compte client n'ont été enregistrées pendant ce délai .</p>
                </article>
                <article>
                    <h2>5. Demande relative aux données personnelles</h2>
                <p className='indent'>Conformémént à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en remplissant le formulaire de contact du site, ou par mail à l'adresse contact@ml-patisserie.fr, ou par voie postale aux coordonnées inscrites en 1.</p>
                <p>Madame LEBESGUE Mélanie s'engage à répondre à votre demande d'accès, de rectification ou d'opposition ou toute autre demande complémentaire d'informations dans un délai raisonnable, qui ne saurait dépasser 1 mois à compter de la réception de votre demande.</p>
                </article>
                <article>
                    <h2>6. Autorité compétente</h2>
                <p className='indent'>La CNIL est l'autorité compétente en matière de protection des données personnelles en France.</p>
                <p>Vous pouvez la contacter à l'adresse www.cnil.fr.</p>
                </article>
            </div>
        </div>
    );
};

export default RGPD;
import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Data from '../context/DataContext';
import MenuBurgerIcon from '../icons/MenuBurgerIcon';
import MenuDeroulantProfil from './MenuDeroulantProfil';
import MenuMobile from './MenuMobile';


const Menu = (props) => {

    const { user, setUser } = useContext(Data);
    const [openMenu, setOpenMenu] = useState(false);

    /**
     * Function to deconnect User
     * by cleaning localStorage
     * and empty user's State
     */
    const deconnection = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        setUser({
            nom: null,
            prenom: null,
            adresse: null,
            telephone: null,
            email: null,
            newsletter: false,
            role: 'anonymous',
            statutBan: null,
            token: null
        })
    }

    return (
        <nav className="desktop">
            <ul className='desktop'>
                <li>
                    <Link to="/">Accueil</Link>
                    <Link to="/boutique">Boutique</Link>
                </li>
                <li>
                    <Link to="/">
                        <img
                            className='logo-accueil'
                            src={process.env.PUBLIC_URL + "/images/logo/ML-logo_quadri_ssfd.png"}
                            alt="" />
                    </Link>
                </li>
                <li>
                    <Link to="/apropos">A Propos</Link>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>

            <Link to="/">
                <img 
                className='logo-accueil responsive' 
                src={process.env.PUBLIC_URL + "/images/logo/ML-logo_quadri_ssfd.png"} 
                alt="" />
            </Link>


            <div className="panier" onClick={props.openPanier}>
                <div className="number_products">{props.panier.length}</div>
                <div className='icon-panier'></div>
                {props.panier.length === 0 && <span className="montant">Votre panier est vide</span>}
                {props.panier.length > 0 && <span className="montant">Voir mon panier</span>}
            </div>
            <div className="profil">
                <div className='icon-user'></div>
                {user.role === 'anonymous' && <a onClick={props.openConnexion}>
                    <span>Connectez-vous </span>
                </a>}

                {user.role !== 'anonymous' && <a onClick={()=> setOpenMenu(true)}>
                    <span >Bienvenue {user.prenom}</span>
                </a>}
                { openMenu && <MenuDeroulantProfil 
                                close={()=> setOpenMenu(false)}
                                deconnection={deconnection}
                                user={user} />}

               
            </div>
            <MenuBurgerIcon className={'menu-button-mobile'} onClick={props.openMenu} />
        </nav >
    );
};

export default Menu;
import React, { useContext, useEffect, useState } from 'react';
import { Connectivity } from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';

const QuantiteCondi = (props) => {
    const { user } = useContext(Data);

    const [quantites, setQuantites] = useState([1, 6, 8, 10, 16, 24, 40]);
    const [newQuantite, setNewQuantite] = useState({ number: '' });

    const [deleteMode, setDeleteMode] = useState(false);


    /**Create and add new allergene to list */
    const createQuantite = () => {
        Connectivity.createQuantite(newQuantite, user.token)
            .then(async response => {
                const resJson = await response.json();
                if (response.status === 201) {
                    const quantiteCreated = { _id: resJson.quantiteId, number: newQuantite.number }
                    const newQuantites = [...quantites];
                    newQuantites.push(quantiteCreated);
                    setQuantites(newQuantites);
                    setNewQuantite({ number: '' });
                }
            })
    }

    /**Delete allergene from list and DB */
    const deleteQuantite = (e, quantite) => {
        e.preventDefault();
        Connectivity.deleteQuantiteById(user.token, quantite._id)
            .then(async response => {
                const result = await response.json();
                if (response.status === 200) {
                    const indexQuantite = quantites.indexOf(quantite.number);
                    const newQuantites = [...quantites];
                    newQuantites.splice(indexQuantite, 1);
                    setQuantites(newQuantites);
                    if (props.quantites.includes(quantite.number)) props.updateWhileSelecting('quantiteCondi', quantite.number)
                }

            })
    }

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
    }

    useEffect(() => {
        /** Fetch all allergenes from DB */
        Connectivity.getAllQuantites()
            .then(async (response) => {
                const result = await response.json();
                if (response.status === 200) {
                    setQuantites(result.quantites)
                }

            })

    }, [])



    return (
        <div className='stack introduction'>
            <h2>Quantités</h2>
            <p>Vous avez choisi la façon dont le conditionnement du produit allait être décliné.</p>
            <p>Il vous faut maintenant choisir quelles quantités vous allez pouvoir proposer pour ce produit.</p>

            <div className={props.errors?.includes('quantiteCondi') ? 'flexContainer error' : 'flexContainer'}>
                {quantites.map((quantite, index) => {
                    return <div
                        className={`chip${props.quantiteCondi.includes(quantite.number) ? ' selected' : ''}`}
                        key={index}
                        name={'quantiteCondi'}
                        onClick={() => props.updateWhileSelecting('quantiteCondi', quantite.number)}
                    >{quantite.number}{deleteMode && <div className='iconDelete' onClick={(e) => deleteQuantite(e, quantite)}></div>}</div>
                })
                }
            </div>
            <p>Vous pouvez aussi en créer une nouvelle.</p>
            <input
                type={'number'}
                value={newQuantite.number}
                placeholder={'18'}
                onChange={e => setNewQuantite({ number: e.target.value })} />
            <button
                className='btn'
                disabled={newQuantite.number <= 0}
                onClick={createQuantite}>CREER QUANTITE</button>
            <h2>Prix à l'unité</h2>
            <p>Vous pouvez maintenant définir le prix à l'unité de votre produit.</p>
            <div className='inputEuro'>

            <input
                type='number'
                placeholder='4.50'
                onChange={props.updateInput}
                name='prix'
                value={props.prix}
                className={props.errors.includes('prix') ? 'error' : ''}
                />
                <div className='currency'>€</div>
                </div>

        </div>
    );
};

export default QuantiteCondi;
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
const settings = require('../../config/settings.json');


const ModalRedirectionPanier = (props) => {

    const [produit, setProduit] = useState({
        produitTitre: '',
        produitCondi: null,
        produitQuantiteCondi: null,
        produitImage: null
    });

    const navToDestination = (e) => {
        e.preventDefault();
        props.openPanier();
        closeModal();
    }

    const closeModal = () => {
        props.setSuccesAjout({
            ...props.succesAjout,
            open: false,
            produit: {}
        })

    }


    useEffect(() => {
        setProduit(props.succesAjout.produit)
        console.log(props.succesAjout.produit);
    }, [props.succesAjout.produit])

    useEffect(()=> {
        setTimeout(closeModal, 6000)
     },[])

    return (
        <div className='fondModal' onClick={closeModal}>
            <div className='succes-ajout'>
                        <p>✔️Produit ajouté au panier</p>
                <div className='produit-ajoute'>
                    <div className='image'>
                        <img src={settings.imgUrl + produit.produitImage} alt={produit.produitTitre} />
                    </div>
                    <div className='infos-produit'>
                    <p>{produit.produitTitre.toUpperCase()}</p>
                    <p>{`${produit.produitQuantiteCondi} ${produit.produitQuantiteCondi > 1 ? produit.produitCondi?.unit?.plural : produit.produitCondi?.unit?.singular}`}</p>
                    </div>
                </div>

                <div className='btn-redirect'>
                    {/* <Link onClick={(e)=>navToDestination(e, '/gourmandises')} to='/gourmandises'>CONTINUER MES ACHATS</Link> */}
                    <a href='' onClick={(e) => navToDestination(e, '/recap')}>VOIR MON PANIER</a>
                </div>
            </div>
        </div>
    );
};

export default ModalRedirectionPanier;
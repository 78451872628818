import React from 'react';

const Presentation = (props) => {
    return (
        <div className='stack introduction'>
            <h2>Présentation du produit</h2>
            <div className='stack'>
                <p>Donnez un nom à votre produit</p>                
                <input
                    type='text'
                    placeholder='Tarte au citron'
                    onChange={props.updateInput}
                    name='titre'
                    value={props.titre}
                    className={props.errors.includes('titre') ? 'error' : ''}
                />
            </div>
            <div className='stack'>
                <p>Décrivez brièvement votre produit</p>
                <textarea
                    placeholder='Ce produit est une tarte au citron'
                    name='description'
                    onChange={props.updateInput}
                    value={props.description}
                    className={props.errors.includes('description') ? 'error' : ''}
                />
            </div>
        </div>
    );
};

export default Presentation;
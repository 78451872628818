import React from 'react';
import { Link } from 'react-router-dom';

const MenuMobile = (props) => {
    return (
        <div className='menu-mobile'>
            <div className='cross' onClick={props.close}></div>
            <ul>
                <li onClick={props.close}>
                    <Link to="/">ACCUEIL</Link>
                </li>
                <li onClick={props.close}>
                    <Link to="/boutique">BOUTIQUE</Link>
                </li>
                <li onClick={props.close}>
                    <Link to="/apropos">A PROPOS</Link>
                </li>
                <li onClick={props.close}>
                    <Link to="/contact">CONTACT</Link>
                </li>
            </ul>
            
        </div>
    );
};

export default MenuMobile;
import React from 'react';

const Footer = (props) => {
    return (
        <footer>
            <div className='image'>

                <img src={process.env.PUBLIC_URL + "/images/logo/ML-logo-compact_quadri_ssfd.png"} alt="" />
            </div>

            <article className='coordonnees'>
                <p>Mélanie Lebesgue</p>
                <p>100 Route de Bertranot</p>
                <p>64370 MESPLEDE</p>
                <p>06 85 25 92 42</p>
                <p>contact@ml-patisserie.fr</p>
            </article>
            {/* <article className='horaires'>
                <h4>Horaires d'ouverture du point de retrait:</h4>
                <div className='jour'>
                    <p>Mardi:</p>
                    <p>9h30 - 12h30</p>
                    <p>16h30 - 18h30</p>
                </div>
                <div className='jour'>
                    <p>Jeudi:</p>
                    <p>9h30 - 12h30</p>
                    <p>16h30 - 18h30</p>
                </div>
                <div className='jour'>
                    <p>Vendredi:</p>
                    <p>9h30 - 12h30</p>
                    <p>16h30 - 18h30</p>
                </div>
                <div className='jour'>
                    <p>Samedi:</p>
                    <p>9h30 - 12h30</p>
                    <p></p>
                </div>
                <div className='jour'>
                    <p>Dimanche:</p>
                    <p>9h30 - 12h30</p>
                    <p></p>
                </div>
            </article> */}
            <article className='cgv'>
                <p onClick={()=> props.setOpenModal('rgpd')}>RGPD</p>
                <p onClick={()=> props.setOpenModal('mentions')}>Mentions Légales</p>
                <p onClick={()=> props.setOpenModal('cgv')}>Conditions générales de vente</p>
            </article>

        </footer>
    );
};

export default Footer;
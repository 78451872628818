import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Connectivity } from '../../middlewares/Connectivity';
import Data from '../context/DataContext';

const Inscription = (props) => {
    const {user, setUser} = useContext(Data)
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);

    const [newClient, setNewClient] = useState({
        nom: '',
        prenom: '',
        telephone: '',
        adresse: {
            ligne1: '',
            ligne2: '',
            codePostal: '',
            ville: ''
        },
        email: '',
        password: '',
        cgv: true
    });
    const [success, setSuccess] = useState(false);


    const inputs = [
        {
            placeholder: 'Votre nom',
            type: 'text',
            name: 'nom',
            required: true,
            value: newClient.nom
        },
        {
            placeholder: 'Votre prénom',
            type: 'text',
            name: 'prenom',
            required: true,
            value: newClient.prenom
        },
        {
            placeholder: 'Votre adresse',
            type: 'text',
            name: 'ligne1',
            required: true,
            value: newClient.adresse.ligne1
        },
        {
            placeholder: "Complément d'adresse (optionnel)",
            type: 'text',
            name: 'ligne2',
            required: false,
            value: newClient.adresse.ligne2
        },
        {
            placeholder: 'Code postal',
            type: 'text',
            name: 'codePostal',
            required: true,
            value: newClient.adresse.codePostal
        },
        {
            placeholder: 'Ville',
            type: 'text',
            name: 'ville',
            required: true,
            value: newClient.adresse.ville
        },
        {
            placeholder: 'Votre téléphone',
            type: 'text',
            name: 'telephone',
            required: true,
            value: newClient.telephone
        },
        {
            placeholder: 'Votre e-mail',
            type: 'text',
            name: 'email',
            required: true,
            value: newClient.email
        },
        {
            placeholder: 'Votre mot de passe',
            type: 'password',
            name: 'password',
            required: true,
            value: newClient.password
        },
    ]


    /**Delete Error input */
    const deleteError = (inputName) => {
        if (errors.includes(inputName)){
            const newErrors = [...errors];
            const indexError = newErrors.indexOf(inputName);
            newErrors.splice(indexError, 1);
            setErrors([...newErrors]);
        }
    }

    /** automatically update state with input */
    const updateInput = (event) => {
        if (event.target.name === "ligne1" ||
            event.target.name === "ligne2" ||
            event.target.name === "codePostal" ||
            event.target.name === "ville") {
                setNewClient({
                    ...newClient,
                    adresse: {
                        ...newClient.adresse,
                        [event.target.name]: event.target.name === "codePostal" ? event.target.value.replace(' ', '') : event.target.value
                    }
                })
            } else if (event.target.name === 'cgv') {
                setNewClient({
                    ...newClient,
                    cgv: event.target.checked
                })
                
            } else {
                
                setNewClient({
                    ...newClient,
                    [event.target.name]: event.target.value
                })
            }
            deleteError(event.target.name);
    }

    /** Verify datas are corrects */
    const checkFormAndSend = (e) => {
        e.preventDefault();

        const arrayErrors = [];
        for (let input of inputs) {
            if (input.required && input.value.length === 0){
                arrayErrors.push(input.name);
            }
        }
        
        if (arrayErrors.length > 0) return setErrors([...arrayErrors]);

        Connectivity.createUser(newClient)
            .then(async (response) => {
                const result = await response.json();
                if (response.status === 201) {
                    setUser({
                        ...user,
                        ...newClient,
                        role: 'client'
                    })
                    setSuccess(true)
                }

            })
    }

    useEffect(()=>{
        if (user.role !== 'anonymous'){
            navigate('/');
        }
    },[user])

    return (
        <main className='page-inscription'>

            <section>
                <h2>Inscription</h2>
               { !success && <form onSubmit={checkFormAndSend}>
                    {inputs.map((input, index) => {
                        return <input
                                    key={index}
                                    onChange={updateInput}
                                    name={input.name}
                                    type={input.type}
                                    placeholder={input.placeholder}
                                    className={errors.includes(input.name) ? 'inputError' : ''}
                                    value={input.value}
                                 />
                    })}
                    
                    <div className="check-news-btn">
                        <div className="news">
                            <label name="newsletter"><input name="newsletter" type="checkbox" /> S'inscrire à la newsletter</label>
                            <label name="cgv"><input onChange={updateInput} name="cgv" type="checkbox" checked={newClient.cgv} required />J'accepte les <a href='#' onClick={props.cgv}>Conditions générales de vente</a></label>
                        </div>
                        <div className="btn">
                            <button type="submit">S'INSCRIRE</button>
                        </div>
                    </div>
                </form>}
                {success && <p>Félicitations, votre inscription a bien été prise en compte. Un email vous sera envoyé pour confirmer votre inscription. Vous pouvez dès à présent vous connecter</p>}
            </section>
        </main>
    );
};

export default Inscription;
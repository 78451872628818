import React from 'react';

const CGV = (props) => {
    return (
        <div className='fond-modal' onClick={props.close}>
            <div className='modal-mrc'>
                <div className='cross'></div>
                <h1>Conditions générales de vente</h1>
                <article>
                    <h2>1.Objet</h2>
                    <p className='indent'>Les présentes Conditions Générales de Vente (ci-après « C.G.V »), définissent les termes et conditions selon lesquels toute personne (ci-après « le Client »), commande des produits vendus par la Pâtisserie Mélanie Lebesgue, sur le site ml-patisserie.fr(ci-après « le Site »).</p>
                </article>
                <article>
                    <h2>2.Application de conditions générales de vente</h2>
                    <p className='indent'>Le fait pour le Client de remplir une commande sur le Site et de la confirmer, vaut pleine et entière acceptation des présentes C.G.V, lesquelles seront seules applicables au contrat qui suit.</p>
                    <p className='indent'>La pâtisserie Mélanie Lebesgue se réserve le droit de modifier en tout ou en partie et à tout moment les présentes C.G.V.</p>
                </article>
                <article>
                    <h2>3.Caractéristiques des produits proposés</h2>
                    <ol className='indent'>
                        <li className='indent'>Les produits proposés au Client sont ceux qui figurent dans le catalogue publié sur le Site, dans la limite des stocks disponibles et sous réserve du bon fonctionnement du Site.</li>
                        <li className='indent'>Chaque produit est illustré par une photographie et un descriptif, qui servent à présenter les caractéristiques essentielles des produits avant commande. Ces éléments sont fournis à titre indicatif, et ne sont pas contractuels. La pâtisserie Mélanie Lebesgue se réserve le droit de modifier le visuel des créations lors de la confection des produits commandés, et d'apporter quelques modifications ou compléments à la recette de base, notamment pour les décors, les pièces spéciales...ou suivant le stock de matière premières disponibles. Ceci sans altérer l'architecture globale du produit commandé qui sera respectée au niveau des saveurs, et sans y ajouter de produits susceptibles de modifier la liste des allergènes décrites dans le descriptif. Si un allergène est ajouté, La Pâtisserie Mélanie Lebesgue en informera le Client avant de procéder à la validation définitive de la commande.</li>
                    </ol>
                </article>
                <article>
                    <h2>4.Prise, confirmation et modification de commande</h2>
                    <ol className='indent bigList'>
                        <li className='indent'>
                        La prise de commande sur le Site peut se faire de deux manières:
                            <ul className='indent subList'>
                                <li>Via le « formulaire de contact », en remplissant des éléments nécessaires à la prise de contact tels que le Nom, Prénom, Mail, Téléphone valides et en saisissant un message précisant l'objet de la demande.</li>
                                <li>Ou via l'ouverture d'un « Compte Client », en remplissant un formulaire d'identification comprenant le Nom, Prénom, adresse, téléphone valides du Client, ainsi que par la saisie d'une adresse mail et d'un mot de passe, qui serviront par la suite d'identifiants de connexion au Compte Client. Ces identifiants sont et devront être tenus confidentiels. En cas d'oubli du mot de passe, un lien de réinitialisation sera automatiquement envoyé sur l'adresse mail du Client, sans que la Pâtisserie Mélanie Lebesgue n'y ait accès ou connaissance</li>
                            </ul>
                        </li>
                        <li>
                        La Pâtisserie Mélanie Lebesgue se réserve le droit de supprimer un compte qui ne respecterait pas les présentes conditions, et notamment en cas d'adresse, telephone ou mail nons valides, et par lesquels La Pâtisserie Mélanie Lebesgue n'arrive pas à entrer en contact avec le Client sous un délai convenable, et notamment à moins de 72h de la date présumée de confection de la commande.
                        </li>
                        <li>
                            <div>
                                <p>Toute commande effectuée via le formulaire de Contact ou via le Compte Client fera l'objet d'une validation préalable par La Pâtisserie Mélanie Lebesgue, qui sera confirmée de manière écrite et transmise par mail, avec un numéro de commande.</p>
                                <p>La Pâtisserie Mélanie Lebesgue se réserve le droit de refuser toute commande qu'elle jugerait non réalisable dans le délai imparti, ou non réalisable avec l'équipement du laboratoire de La Pâtisserie, qui revêt un caractère artisannal. Ceci notamment en cas de commandes en grande quantité, ou pour un événement spécial, et pour lesquels La Pâtisserie Mélanie Lebesgue ne pourrait pas donner suite en raison d'un délais ou de moyens jugés insuffisants, sans que cela ne donne lieu à quelconque réclammation ou dédomagement pour le Client.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>Pour passer commande, le Client s'engage à remplir les éléments constitutifs de sa commande avec précision et rigueur. A ce titre, le Client s'engage à renseigner intégralement les différentes rubriques nécessaires au bon traitement de sa commande :</p>
                                <p>- Choix du /des produits avec quantités, nombre de parts par produits</p>
                                <p>- Choix de l'adresse de facturation</p>
                                <p>- Choix de la date et heure au point retrait, en tenant compte des heures d'ouvertures</p>
                                <p>- Demande de livraison par La Pâtisserie Mélanie Lebesgue, sur la commune de Mesplède ou sur les communes limitrophes (sur accord préalable et suivant possibilités pour la date et l'heure)</p>
                                <p>- Vérification et validation de la commande et des Conditions Générales de Vente</p>
                                <p>En cliquant sur l'icône du Site « Réserver maintenant », le client reconnaît avoir la pleine capacité juridique pour s'engager au titre des Conditions Générales de Ventes, lesquelles sont seules applicables au contrat qui suit.</p>
                                <p>La validation de la commande vaut donc acceptation sans réserves des présentes C.G.V, et le Client déclare avoir pris connaissance des présentes conditions et les accepter avant la validation de sa commande.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>Le Site est accessible au Client 24 heures sur 24, 7 jours sur 7, sauf en cas d'opération de maintenance, de défaillance de l'hébergeur du Site, ou de problème de connection quel qu'il soit.</p>
                                <p>La Pâtisserie Mélanie Lebesgue se réserve le droit de fermer temporairement le Site en cas de Congés, de Maladie, ou de périodes festives telles que les fêtes de fin d'années ou autres, nécessitant une gestion particulière des commandes.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>La Pâtisserie Mélanie Lebesgue confirmera ou refusera la commande sous 48 heures ouvrables :</p>
                                <p>- Par mail, à l'adresse indiquée par le Client et s'il est passée via le Compte Client.</p>
                                <p>- Par mail ou par téléphone si celle-ci est passée via le formulaire de Contact</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>Modification de commande :</p>
                                <p>Toutes commandes passées à tavers le compte client ou le formulaire de contact pourront faire l'objet d'une modification ou d'une annulation dans un délais maximum de 72 heures avant retrait ou livraison, et uniquement par téléphone au 06.85.25.92.42, si ces commandes ont un montant global inférieur à 50 euros.</p>
                                <p>En cas de demande de modification ou d'annulation d'une commande d'un montant supérieur à 50 euros, cette demande devra se faire dans un délais maximum de 7 jours avant retrait ou livraison, et uniquement par téléphone au 06.85.25.92.42, et dans le cas d'une modification, ne pourra concerner que des modifications simples (nombre de parts, rajout ou suppression d'un élément de décor, heure de retrait ou livraison...).</p>
                                <p>La Pâtisserie Mélanie Lebesgue se réserve le droit de refuser toute autre demande de modifications intervenues dans un délais maximum de 14 jours avant retrait ou livraison, et pour lesquelles elle ne serait pas en mesure d'honorer la demande du client, pour des raisons de délais d'approvisionnement de matières premières , d'essais gustatifs et artistiques, ou encore organisationnelles....</p>
                            </div>
                        </li>
                    </ol>
                </article>
                <article>
                    <h2>5.Moyens de paiement</h2>
                    <p className='indent'>Les commandes seront à régler au moment du retrait ou de la livraison, soit par CB, chèque à l'ordre de « Mélanie Lebesgue », ou en Espèces.</p>
                </article>
                <article>
                    <h2>6.Droit de rétractation</h2>
                    <ol className='indent'>
                        <li>
                            <div>
                                <p> Conformément à la législation en vigueur, le droit de rétractation ne s’applique pas aux contrats portant sur la fourniture des produits périssables, agroalimentaires, tels que les produits vendus sur le Site. Aussi, le Client ne saurait s’en prévaloir.</p>
                                <p>Produits exclus du Droit de Rétractation de 14 jours, selon la législation en vigueur:</p>
                                <ol className='romain'>
                                    <li>les biens confectionnés selon les spécifications du consommateur ou nettement personnalisés : cela concerne notamment les commandes de pièces montées, entremets superposés, pièces de cérémonies, grands entremets rectangulaires, gâteaux d’anniversaire personnalisés, bûches, coffrets macarons...</li>
                                    <li>les produits frais, les produits susceptibles de se détériorer ou de se périmer rapidement tels que les macarons, les pâtisseries, les petits fours secs, les canapés, les cakes...</li>
                                    <li>les biens descellés par le Client après la livraison et qui ne peuvent être renvoyés pour des raisons d’hygiène ou de protection de la santé.</li>

                                </ol>
                                <p>Pour les autres produits, le Client dispose d’un délai de quatorze (14) jours à compter de la réception du produit pour exercer son droit de rétractation 	auprès de la Pâtisserie Mélanie Lebesgue, sans avoir à justifier de motifs ni à payer de pénalité, à fin d’échange (sous réserve de disponibilité) ou de remboursement. Si ce délai expire un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu’au premier jour ouvrable suivant.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>Le droit de rétractation peut être exercé sur papier libre ou par email. En cas de transmission de la demande par email , un accusé de réception sera communiqué 	au Client par la Pâtisserie Mélanie Lebesgue.</p>
                                <p>Les produits sont à retourner dans les quatorze (14) jours suivant la notification à la Pâtisserie Mélanie Lebesgue de la décision de rétractation du Client, accompagnés de la facture d’achat dans leur emballage d’origine et en parfait état permettant leur nouvelle commercialisation à l’état neuf. Les produits endommagés, salis ou incomplets ne seront pas repris.</p>
                                <p>Les retours doivent être expédiés ou ramenés à l’adresse suivante:</p>
                                <blockquote>
                                    <p>Pâtisserie Mélanie Lebesgue</p>
                                    <p>100 Route de Bertranot</p>
                                    <p>64370 Mesplède</p>
                                </blockquote>
                                <p>En cas d’exercice du droit de rétractation dans le délai susvisé, seul le prix du ou des produits achetés et les éventuels frais de livraison seront remboursés ; les frais de retour restant à la charge du Client.</p>
                                <p>L’échange (sous réserve de disponibilité) ou le remboursement sera effectué dans un délai de quatorze (14) jours à compter de la réception, par la Pâtisserie Mélanie Lebesgue, des produits retournés par le Client dans les conditions du présent article.</p>
                            </div>
                        </li>
                    </ol>
                </article>
                <article>
                    <h2>7.Rupture de stocks</h2>
                    <ol className='indent'>
                        <li>Les produits vendus sur le Site sont proposés dans la limite des stocks disponibles (matières premieres, boitage...). Un produit devenu indisponible sera retiré dans les plus brefs délais du catalogue du Site de vente à distance.</li>
                        <li>Toutefois, en cas de rupture de stocks d'un produit présent dans le catalogue et commandé par le Client, la Pâtisserie Mélanie Lebesgue prendra contact avec le Client par téléphone ou par mail dans les plus brefs délais, afin de lui proposer de décaler la date de retrait ou de livraison, ou encore de substituer avec un produit équivalent en qualité et en prix, ou enfin de proposer d'annuler la commande sans conditions.</li>
                        <li>Les produits de la Pâtisserie Mélanie Lebesgue sont fabriqués de manière artisanale et sont disponibles en quantités limitées. Aussi, il est important de noter que les commandes passées durant les périodes de fêtes (Fêtes de fin d'année, Pâques, Saint-Valentin...) pourront entraîner des risques de rupture de stocks plus importants comparativement à celles effectuées pendant les autres périodes de l'année.</li>
                    </ol>
                </article>
                <article>
                    <h2>8.Prix</h2>
                    <p className='indent'>Les produits vendus ne sont pas soumis à la TVA, conformément à l'article 293B du CGI.</p>
                </article>
                <article>
                    <h2>9.Click and collect</h2>
                    <p className='indent'>Dans le cas d'une commande avec retrait au Point retrait de la Pâtisserie Mélanie Lebesgue, le Client s'engage à venir retirer sa commande au jour et créneau horaire qu'il aura choisi.</p>
                    <p className='indent'>Les produits étant périssables, la Pâtisserie Mélanie Lebesgue ne pourra conserver une commande qui aurait été oubliée par le Client. La Pâtisserie Mélanie Lebesgue mettra dans ce cas tout en œuvre pour contacter rapidement le Client par téléphone ou par mail. Si le contact reste infructueux et que le produit a atteint sa limite de péremption, la Pâtisserie Mélanie Lebesgue procèdera à la destruction du produit périssable et se réserve le droit d'en exiger le règlement intégral.</p>
                </article>
                <article>
                    <h2>10.Transfert de propriété et transfert de risques</h2>
                    <p className='indent'>La Pâtisserie Mélanie Lebesgue assure la marchandise jusqu'à son retrait effectif par le Client en point retrait ou jusqu'au lieu de livraison.</p>
                    <p className='indent'>Lors du transfert de marchandise, le Client et Mélanie Lebesgue vérifieront ensemble l'état des produits confiés. La Pâtisserie Mélanie Lebesgue n'assumera en aucun cas tout dommages causés après transfert de la marchandise au Client (chute de gâteau, mauvais calage dans le véhicule du Client, écrasement par empilement...).</p>
                    <p className='indent'>Il est important de noter qu'il incombe au Client lors du transfert de la marchandise de respecter la chaîne du froid lorsqu'il s'agit d'un produit frais. La Pâtisserie Mélanie Lebesgue ne pourrait être tenue responsable de problèmes sanitaires intervenus à cause d'une rutpure dangereuse de la chaîne du froid de la part du Client. Des conseils de stockage, réfrigérés ou non, et de durée de conservation sont indiqués sur chaque descriptif du produit, permettant au Client de prendre toutes les mesures nécessaires pour assurer une bonne sécurité sanitaire des produits lors de leur stockage et de leur consommation.</p>
                    <p className='indent'>La liste des allergènes est également inscrite en regard de chaque produit proposé sur le Site, et il appartient au Client de lire et de communiquer cette liste à d'éventuels convives, étant donné que les produits vendus n'entrent pas dans le cadre de produits pré-emballés.</p>
                </article>
                <article>
                    <h2>11.Réclammations et remboursement</h2>
                    <p className='indent'>Les réclammations ou contestations seront reçues avec une bienveillance attentive.Toute réclammation sera prise en compte dans un délai de 72h suivant la réception des produits par le Client. Le Client devra exposer la nature de sa demande par téléphone au 0685259242 ou par mail à : contact@ml-patisserie.fr , et préciser le numéro de commande correspondant au produit objet du litige, ainsi que tout justificatif pouvant étayer la demande (photos...). Sans ces éléments justificatifs, le traitement de la réclamation ne pourra avoir lieu. La Pâtisserie Mélanie Lebesgue s'engage à trouver une issue commerciale juste, soit sous forme de geste commercial, soit sous forme de remboursement si la réclammation est dûment justifiée.</p>
                </article>
                <article>
                    <h2>12.Propriété intellectuelle</h2>
                    <p className='indent'>La structure, mise en page, charte graphique, textes, images, code source, base de données, sons, newsletters et tout autre élément composant le Site sont et demeurent la propriété intellectuelle et exclusive de la Pâtisserie Mélanie Lebesgue, et sont protégés par le droit d'auteur.</p>
                    <p className='indent'>De même, le Client ne doit en aucun cas revendre les produits achetés par l'intermédiaire du Site, sauf accord expresse de Mélanie Lebesgue.</p>
                </article>
                <article>
                    <h2>13.Force majeure</h2>
                    <p className='indent'>En cas de force majeure telle que décrit dans l'article 1148 du Code Civil, Le Client ou la Pâtisserie Mélanie Lebesgue ne sauraient être tenus responsable de la non-exécution totale ou partielle de l'une de ses obligations décrites dans les présentes C.G.V.</p>
                </article>
                <article>
                    <h2>14.Loi applicable</h2>
                    <p className='indent'>Les présentes conditions générales sont régies par le droit français. En cas de traduction, seule la version française fait foi. Le Tribunal de Commerce de Pau, sis 3 Rue Duplaa 64000 Pau est seul compétent en cas de litige dont la résolution par voie amiable a été épuisée.</p>
                </article>
            </div>

        </div>
    );
};

export default CGV;
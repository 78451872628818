import React from 'react';
import UploadImage from './UploadImage';
import settings from '../../../config/settings.json';

const Photos = (props) => {

    return (
        <div className='stack introduction'>
            <h2>Photo du produit</h2>
            <div className='stack'>
                <p>Choisissez la photo qui met le plus en valeur votre produit.</p>                
                <UploadImage 
                    updateInput={props.updateInput} 
                    image={!props.editing ?  props.images[0] : null}
                    imageUrl={props.editing ? `${settings.imgMiniUrl}${props.images[0]}` : null}
                    errors={props.errors}
                />
        </div>
        </div>
    );
};

export default Photos;
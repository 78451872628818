import { useScrollTrigger } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Connectivity } from '../../middlewares/Connectivity';
import ItemProduitCompact from '../page-gourmandises/ItemProduitCompact';
import SelectCategories from '../page-gourmandises/SelectCategories';

const Gourmandises = (props) => {

    const trigger = useScrollTrigger();

    const inputRef = useRef()

    const openDetailsProduit = (produit) => {
        props.setDetailsProduit({
            open: true,
            produit
        })
    }
    const [options, setOptions] = useState({
        categorie: '',
        orderBy: ''
    })

    const [produitsFiltered, setProduitsFiltered] = useState([]);

    const [categories, setCategories] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const updateInput = (e) => {
        setOptions({
            ...options,
            [e.target.name]: e.target.value
        })
    }

    const getInputPosition = () => {
        setAnchorEl({ x: inputRef.current.offsetLeft, y: inputRef.current.offsetTop, height: inputRef.current.offsetHeight, width: inputRef.current.offsetWidth })
    }

    useEffect(() => {
        Connectivity.getAvailableProduits()
            .then(async (response) => {
                const json = await response.json();
                if (response.status === 200) {
                    props.setListProduits(json.produits)
                }
            })
        Connectivity.getAllCategories()
            .then(async (response) => {
                const result = await response.json();
                if (response.status === 200) {
                    setCategories(result.categories)
                }

            })


    }, [])

    useEffect(() => {
        if (options.categorie.length === 0) {
            setProduitsFiltered([]);
        } else {
            const filteredProduits = props.listProduits.filter(produit => produit.categorie === options.categorie);
            setProduitsFiltered([...filteredProduits]);
        }
    }, [options])


    // useEffect(() => {
    //     if (trigger) {
    //         getInputPosition();
    //         console.log(anchorEl);
    //     }
    // }, [trigger])

    return (
        <main className='gourmandises'>
            <h1>Boutique</h1>
            <div className="banniere"></div>
            <section className="barreTri">
                <label name="categories" className='referedLabel'>
                    Afficher
                </label>
                     <SelectCategories
                        categories={categories}
                        options={options}
                        setOptions={setOptions}
                       
                    />
                {/* <label name="tierPar">
                    Trier par:
                    <input name='orderBy' readOnly onChange={updateInput} value={options.orderBy} />
                </label> */}
            </section>
            <section className='list-produits'>

                {props.listProduits.length > 0 && options.categorie.length === 0 && props.listProduits.map((produit, index) => {
                    return <ItemProduitCompact
                        key={index}
                        img={produit.images[0] ? produit.images[0] : 'default.png'}
                        titre={produit.titre}
                        description={produit.description}
                        prix={produit.prix}
                        conditionnement={produit.conditionnement}
                        openModalProduit={(e) => { e.preventDefault(); openDetailsProduit(produit) }}
                    />
                })}

                {options.categorie.length > 0 && produitsFiltered.map((produit, index) => {
                    return <ItemProduitCompact
                        key={index}
                        img={produit.images[0] ? produit.images[0] : 'default.png'}
                        titre={produit.titre}
                        description={produit.description}
                        prix={produit.prix}
                        conditionnement={produit.conditionnement}
                        openModalProduit={(e) => { e.preventDefault(); openDetailsProduit(produit) }}
                    />
                })}

            </section>
        </main >
    );
};

export default Gourmandises;
import { CircularProgress, Paper, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../assets/scss/verifemail.module.scss';
import { Connectivity } from '../../middlewares/Connectivity';
import Data from '../context/DataContext';

const VerifEmail = () => {

    const {user, setUser} = useContext(Data);

    const location = useLocation();

    const navigate = useNavigate();

    const [request, setRequest] = useState({
        status: 'pending',
        message: 'Vérification de votre adresse email'
    })

    useEffect(()=>{
        const token = location.search.split('token=')[1];
        if (!token) navigate('/');
        if(token && token.length > 0){
            Connectivity.validateEmail(token)
                .then(async response => {
                    const result = await response.json();
                    if (response.status !== 200) {
                        setRequest({
                            ...request,
                            status: 'error',
                            message: result.message
                        })
                    }
                    if (response.status === 200){
                        setRequest({
                            ...request,
                            status: 'success',
                            message: result.message
                        })
                        localStorage.setItem('token', result.token)
                        setUser({
                            ...user,
                            ...result.user,
                            token: result.token
                        })
                    }
                })
        }
    },[])

    useEffect(()=>{
        if (request.status !== 'pending') {
            setTimeout(()=> navigate('/gourmandises'), 4000);
        }

    },[request.status])

    return (
        <main className={styles.verif}>

         <section className={styles.verifSection}>
            <h2>{request.message}</h2>
            {request.status === 'pending' && <CircularProgress />}
            {request.status === 'success' && <p>✅ Vous allez automatiquement être redirigé</p>}
          
        </section>
    </main>
    );
};

export default VerifEmail;
import React from 'react';

const MentionsLegales = (props) => {
    return (
        <div className='fond-modal' onClick={props.close}>
            <div className='modal-mrc'>
                <div className='cross'></div>
                <h1>Mentions Légales</h1>
                <h2>Identification de la structure :</h2>
                <blockquote>
                <p>Mélanie LEBESGUE</p>
                <p>Pâtisserie artisanale</p>
                <p>100 Route de Bertranot</p>
                <p>64370 Mesplède</p>
                <p>N°SIREN 512724212</p>
                <p>TVA non applicable, art 293B du CGI</p>
                </blockquote>
            </div>
            
        </div>
    );
};

export default MentionsLegales;
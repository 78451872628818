import React, { useContext, useEffect, useState } from 'react';
import {Connectivity} from '../../../middlewares/Connectivity';
import Admin from '../../context/AdminContext';
import Data from '../../context/DataContext';
import AlertModal from '../../modals/AlertModal';
import ItemClient from '../page-clients/ItemClient';
import ModalInscriptionClient from '../page-clients/ModalInscriptionClient';

const ClientsAdmin = () => {
    const { user } = useContext(Data);
    const { 
        users, setUsers
     } = useContext(Admin);

     const [modalCreate, setModalCreate] = useState({
         open: false,
         user: null
     });
     const [modalDelete, setModalDelete] = useState({
         open: false,
         user: null
     });


     const alertDeleteUser = (people) => {
         setModalDelete({
             open: true,
             user: people
         });
     }

     const deleteUser = () => {
         Connectivity.deleteUser(modalDelete.user._id, user.token)
            .then(res => {
                if (res.status === 200){
                    const indexUser = users.indexOf(modalDelete.user);
                    const newUsers = users;
                    newUsers.splice(indexUser, 1);
                    setUsers(newUsers);
                }
                setModalDelete({
                    open: false,
                    user: null
                });
            })
     }


    useEffect(() => {
        /** Fetch all users */
        Connectivity.getAllUsers(user.token)
            .then(async (response) => {
                if (response.status === 200) {
                    const result = await response.json();
                    setUsers(result.users)
                }
            })
    }, [])



    return (
        <>
            <section className='clients-admin'>
                <h2>CLIENTS</h2>
                <button className='create-account' onClick={() => setModalCreate({open: true, user: null})}>Créer un compte</button>
                <article className='header-clients'>
                    <h3 className='medium'>Inscription</h3>
                    <h3>Nom complet</h3>
                    <h3>Téléphone</h3>
                    <h3 className='large'>Email</h3>
                    <h3 className='large'>Adresse</h3>
                    <h3>Statut</h3>
                    <h3 className='small'></h3>
                </article>

                {users && users.map((people, index) => {
                    return <ItemClient
                        key={index}
                        odd={index % 2 === 0 ? true : false}
                        creationDate={people.creationDate}
                        prenom={people.prenom}
                        nom={people.nom}
                        telephone={people.telephone}
                        adresse={people.adresse}
                        email={people.email}
                        statut={people.validationDate ? 'Validé' : 'Non vérifié'}
                        edit={() => setModalCreate({open: true, user: {... people}})}
                        delete={()=>alertDeleteUser(people)} />
                })}

            </section>
            {modalCreate.open && <ModalInscriptionClient 
                                    users={users} 
                                    setUsers={setUsers} 
                                    editUser={modalCreate.user}
                                    close={() => setModalCreate({open: false, user: null})} />}
            {modalDelete.open && <AlertModal 
                                    action={deleteUser} 
                                    close={() => setModalDelete({open: false, user: null})} />}
        </>
    );
};

export default ClientsAdmin;
import { addDays, isMonday, isWednesday, isWithinInterval } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, Outlet, useLocation } from "react-router-dom";

import './App.scss';
import './assets/import.scss';
import AsideMenuAdmin from "./components/admin/menu/AsideMenuAdmin";
import EditingMenu from "./components/admin/menu/EditingMenu";
import MenuAdmin from "./components/admin/menu/MenuAdmin";
import StepperDown from "./components/admin/menu/StepperDown";
import AccueilAdmin from "./components/admin/pages/AccueilAdmin";
import CarrouselAdmin from "./components/admin/pages/CarrouselAdmin";
import ClientsAdmin from "./components/admin/pages/ClientsAdmin";
import CommandesAdmin from "./components/admin/pages/CommandesAdmin";
import CreationProduit from "./components/admin/pages/CreationProduit";
import MessagesAdmin from "./components/admin/pages/MessagesAdmin";
import ProduitsAdmin from "./components/admin/pages/ProduitsAdmin";
import { AdminContext } from "./components/context/AdminContext";
import Data from "./components/context/DataContext";
import Footer from "./components/footer/Footer";
import Menu from "./components/menu/Menu";
import MenuMobile from "./components/menu/MenuMobile";
import ConnexionModal from "./components/modals/ConnexionModal";
import MdpOublieModal from "./components/modals/MdpOublieModal";
import ModalRedirectionPanier from "./components/modals/ModalRedirectionPanier";
import PanierModal from "./components/modals/PanierModal";
import DetailsProduit from "./components/page-gourmandises/DetailsProduit";
import Accueil from "./components/pages/Accueil";
import APropos from "./components/pages/APropos";
import CGV from "./components/pages/CGV";
import Contact from "./components/pages/Contact";
import Gourmandises from "./components/pages/Gourmandises";
import Inscription from "./components/pages/Inscription";
import MentionsLegales from "./components/pages/MentionsLegales";
import Profil from "./components/pages/Profil";
import RecapCommande from "./components/pages/RecapCommande";
import RGPD from "./components/pages/RGPD";
import UpdatePassword from "./components/pages/UpdatePassword";
import VerifEmail from "./components/pages/VerifEmail";
import { Connectivity } from "./middlewares/Connectivity";

function App() {

    const { user, setUser } = useContext(Data);

    const location = useLocation();

    const [openModal, setOpenModal] = useState(null);
    const [showPanier, setShowPanier] = useState(false);
    const [showConnexion, setShowConnexion] = useState(false);
    const [showMdpOublie, setShowMdpOublie] = useState(false);
    const [succesAjout, setSuccesAjout] = useState({
        open: false,
        produit: {}
    });
    const [panier, setPanier] = useState([]);
    const [commande, setCommande] = useState({
        montantTotal: null,
        retraitDate: addDays(new Date(), 3),
        retraitHoraire: ''
    });
    const [listProduits, setListProduits] = useState([]);
    const [detailsProduit, setDetailsProduit] = useState({
        open: false
    });



    const dateRange = (day) => {
        return (
            isMonday(day) ||
            isWednesday(day) ||
            isWithinInterval(day, { start: new Date(2022, 3, 19), end: new Date(2022, 4, 1) }) ||
            isWithinInterval(day, { start: new Date(2022, 6, 25), end: new Date(2022, 7, 28) }) ||
            isWithinInterval(day, { start: new Date(2022, 9, 24), end: new Date(2022, 10, 6) })
        )

    }

    useEffect(() => {
        const maxTry = 50;
        for (let count = 3; count < maxTry; count++) {
            let minimum = addDays(new Date(), count);
            if (!dateRange(minimum)) return setCommande({
                ...commande,
                retraitDate: minimum
            });

        }
    }, [])




    /** 
     * Connect user if userId and token are valids on app loading 
     */
    useEffect(() => {
        /** Get Token and UserId from localStorage */
        const token = localStorage.getItem('token');

        /** If there are token and userId */
        if (token) {

            /** Use them to fetch user's datas */
            Connectivity.getUserDatas(token)
                .then(async (res) => {
                    const response = await res.json();

                    /** If token and userId respond with success status */
                    if (res.status === 200) {

                        /** set user's datas and token to State */
                        setUser({
                            ...user,
                            ...response.user,
                            token: token
                        });

                        /**Otherwise... */
                    } else {
                        //TODO Handle error
                        console.log(response);
                    }
                })
        }

    }, [])

    useEffect(() => {
        if (panier.length > 0) {
            /** Every times panier is modified it updates total amount of commande */
            const totals = [];
            panier.forEach(element => {
                totals.push(element.produitMontantTotal)
            });
            const sommeTotal = totals.reduce(
                (previousValue, currentValue) => previousValue + currentValue
            )
            /** Every times panier is modified it updates total number of produits  */
            const nombreProduits = [];
            panier.forEach(element => {
                nombreProduits.push(element.produitQuantiteAchat)
            });
            const nbProdTotal = nombreProduits.reduce(
                (previousValue, currentValue) => previousValue + currentValue
            )
            setCommande({
                ...commande,
                nbProduits: nbProdTotal,
                montantTotal: sommeTotal
            })
        } else {
            /** If panier is empty, reset all values */
            setCommande({
                ...commande,
                nbProduits: null,
                montantTotal: null
            });
        }


    }, [panier])


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location])


    return (
        <>
            <Routes>
                <Route path="/" element={<Layout
                    panier={panier}
                    openPanier={() => setShowPanier(true)}
                    setOpenModal={setOpenModal}
                    openConnexion={() => setShowConnexion(true)}
                />} >
                    <Route index element={<Accueil listProduits={listProduits} setListProduits={setListProduits} />} />
                    <Route path="apropos" element={<APropos />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="boutique" element={<Gourmandises
                        listProduits={listProduits}
                        setListProduits={setListProduits}
                        detailsProduit={detailsProduit}
                        setDetailsProduit={setDetailsProduit}
                    />} />
                    <Route path="inscription" element={<Inscription cgv={() => setOpenModal('cgv')} />} />
                    <Route path="profil" element={<Profil />} />
                    <Route path="recap" element={<RecapCommande
                        panier={panier}
                        setPanier={setPanier}
                        commande={commande}
                        setCommande={setCommande}
                        setShowConnexion={setShowConnexion}
                        cgv={() => setOpenModal('cgv')}
                    />} />
                    <Route path="verifemail" element={<VerifEmail />} />
                    <Route path="passoublie" element={<UpdatePassword />} />
                </Route>
                <Route path="/admin" element={<LayoutAdmin />}>
                    <Route index element={<AccueilAdmin />} />
                    <Route path='clients' element={<ClientsAdmin />} />
                    <Route path='produits' element={<ProduitsAdmin />} />
                    <Route path='nouveau-produit' element={<CreationProduit />} />
                    <Route path='modification-produit' element={<CreationProduit editing={true} />} />
                    <Route path='commandes' element={<CommandesAdmin />} />
                    <Route path='carrousel' element={<CarrouselAdmin />} />
                    <Route path='messages' element={<MessagesAdmin />} />
                </Route>
            </Routes>
            {showPanier && <PanierModal
                setShowPanier={setShowPanier}
                panier={panier}
                setPanier={setPanier}
                commande={commande}
                setCommande={setCommande}
            />}
            {showConnexion && <ConnexionModal close={() => setShowConnexion(false)} setShowMdpOublie={setShowMdpOublie} />}
            {showMdpOublie && <MdpOublieModal close={() => setShowMdpOublie(false)} />}

            {detailsProduit.open && <DetailsProduit
                detailsProduit={detailsProduit}
                setDetailsProduit={setDetailsProduit}
                produit={detailsProduit.produit}
                panier={panier}
                setPanier={setPanier}
                succesAjout={succesAjout}
                setSuccesAjout={setSuccesAjout} />}

            {succesAjout.open && <ModalRedirectionPanier
                succesAjout={succesAjout}
                setSuccesAjout={setSuccesAjout}
                openPanier={() => setShowPanier(true)}
            />}

            {openModal === 'rgpd' && <RGPD close={() => setOpenModal(null)} />}
            {openModal === 'mentions' && <MentionsLegales close={() => setOpenModal(null)} />}
            {openModal === 'cgv' && <CGV close={() => setOpenModal(null)} />}

        </>
    );
}

const Layout = (props) => {
    const [menuMobile, setMenuMobile] = useState(false);

    return (
        <>
            <header>

                <Menu
                    panier={props.panier}
                    openPanier={props.openPanier}
                    openConnexion={props.openConnexion}
                    openMenu={() => setMenuMobile(true)}
                />
                {menuMobile && <MenuMobile close={() => setMenuMobile(false)} />}

            </header>
            <Outlet />
            <Footer setOpenModal={props.setOpenModal} />

        </>
    );
}




const LayoutAdmin = (props) => {
    const { user, setUser } = useContext(Data);
    const navigate = useNavigate();
    const location = useLocation();
    const [isEditing, setIsEditing] = useState({
        mode: null
    });
    const [stepper, setStepper] = useState(0);
    const [errors, setErrors] = useState([]);
    const [errorsMenu, setErrorsMenu] = useState([]);
    const [openMenu, setOpenMenu] = useState(false);



    const [newProduit, setNewProduit] = useState({
        titre: '',
        description: '',
        prix: '',
        categorie: "",
        conditionnement: {
            type: '',
            unit: {
                singular: '',
                plural: ''
            },
            pack: {
                singular: '',
                plural: ''
            }
        },
        conseilDegustation: '',
        conseilConservation: '',
        quantiteCondi: [],
        allergenes: [],
        statutPublication: true,
        images: [],
        newImages: []
    })


    const startEditing = (mode) => {
        setOpenMenu(true);
        setIsEditing({
            ...isEditing,
            mode: mode
        });
    };

    const stopEditing = () => {
        setOpenMenu(false);
        setIsEditing({
            ...isEditing,
            mode: null
        });
    };

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };


    const verifyFields = (fields) => {
        let errors = [];
        fields.forEach(field => {
            if (field.name === 'conditionnement') return
            if (field.required && (newProduit[field.name] === '' || newProduit[field.name].length === 0) && field.name !== 'images') {
                errors.push(field.name);
            }
            if (field.required  && field.name === 'images') {
                if (newProduit[field.name].length === 0 && newProduit.newImages.length === 0) {
                errors.push(field.name);
                }
            }
        });
        setErrors(errors);
        return errors;
    }

    useEffect(() => {
        if (!user.token) {
            const localToken = localStorage.getItem('token');
            if (!localToken) return navigate('/');
            /** Use them to fetch user's datas */
            Connectivity.getUserDatas(localToken)
                .then(async (res) => {
                    const response = await res.json();

                    /** If token and userId respond with success status */
                    if (res.status === 200) {

                        /** set user's datas and token to State */
                        setUser({
                            ...user,
                            ...response.user,
                            token: localToken
                        });

                        if (response.user.role === 'client') {
                            navigate('/')
                        }

                        /**Otherwise... */
                    } else {
                        //TODO Handle error
                        navigate('/');
                    }
                })

        }

    }, [])

    useEffect(() => {
        if (location.pathname === '/admin/nouveau-produit') {
            startEditing('creation');
        }
        if (location.pathname === '/admin/modification-produit') {
           startEditing('edition');
        }
        if (location.pathname !== '/admin/modification-produit' &&
            location.pathname !== '/admin/nouveau-produit') {
            setIsEditing({
                ...isEditing,
                mode: null
            });
        }
    }, [location])

    useEffect(() => {
       if (!isEditing.mode) {
         setNewProduit({
            titre: '',
            description: '',
            prix: '',
            categorie: "",
            conditionnement: {
                type: '',
                unit: {
                    singular: '',
                    plural: ''
                },
                pack: {
                    singular: '',
                    plural: ''
                }
            },
            conseilDegustation: '',
            conseilConservation: '',
            quantiteCondi: [],
            allergenes: [],
            statutPublication: true,
            images: [],
            newImages: []
        })
        setStepper(0);
        setOpenMenu(false);
       }
       
    }, [isEditing])
    return (
        <>
            <AdminContext>
                <header>
                    {!isEditing.mode && <MenuAdmin />}
                </header>
                <main className={(openMenu && isEditing.mode) ? "main-edit" : (isEditing.mode && !openMenu) ? "main-edit-close" : "admin-main"}>
                    {!isEditing.mode && <AsideMenuAdmin />}
                    {isEditing.mode && <EditingMenu
                        stopEditing={stopEditing}
                        stepper={stepper}
                        setStepper={setStepper}
                        errors={errorsMenu}
                        setErrors={setErrorsMenu}
                        newProduit={newProduit}
                        openMenu={openMenu}
                        toggleMenu={toggleMenu}
                    />}
                    <Outlet
                        context={{
                            errors, setErrors,
                            startEditing, stopEditing,
                            stepper, setStepper,
                            newProduit, setNewProduit,
                            toggleMenu,
                            openMenu
                        }}
                    />
                    {isEditing.mode && <StepperDown
                        steps={9}
                        activeStep={stepper}
                        handleNext={() => setStepper(stepper + 1)}
                        handleBack={() => setStepper(stepper - 1)}
                        formIsCompleted={errorsMenu.length === 0}
                        newProduit={newProduit}
                        setNewProduit={setNewProduit}
                        mode={isEditing.mode}
                        openMenu={openMenu}
                        verifyFields={verifyFields}
                    />}
                </main>
            </AdminContext>
        </>
    );
}


export default App;

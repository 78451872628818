import React, { useContext, useEffect } from 'react';
import styles from '../../../assets/scss/admin/ModalDetailsCommande.module.scss';
import { Connectivity } from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';
const settings = require('../../../config/settings.json');

const ModalDetailsCommande = (props) => {

    const { user } = useContext(Data);

    const updateStatut = (e, statutValidation) => {
        const date = Date.now();
        Connectivity.updateStatutCommande(props.id, { [e.target.name]: {statut: statutValidation, date: date} }, user.token)
            .then(async result => {
                const response = await result.json()
                if (result.status !== 200) return console.log(response);
                props.setModalDetails({
                    ...props.modalDetails,
                    ...response.commande
                })
                const newArray = [...props.commandes];
               const indexUpdated = props.commandes.findIndex(commande => commande._id === response.commande._id)
               newArray.splice(indexUpdated, 1, response.commande);
               props.setCommandes([...newArray]);
            })
    }

    return (
        <>
            <div className='fond-modal'>
                <div className={styles.modalDetailsCommande}>

                    <div className={styles.infosClients}>
                        <p>{props.fullName}</p>
                        <p>{props.adresse.ligne1}</p>
                        <p>{props.adresse.codePostal + ' ' + props.adresse.ville}</p>
                        <p>{props.email}</p>
                        <p>{props.telephone}</p>
                    </div>
                    <div className={styles.statutsCommande}>
                        <div className={styles.labels}>
                            <h5>Date commande</h5>
                            <h5>Date retrait</h5>
                            <h5>Date paiement</h5>
                            <h5>Validée le</h5>
                        </div>
                        <div className={styles.statutsDate}>
                            <p>{props.creationDate.toLocaleString().slice(0, 10)}</p>
                            <p>{props.retraitDate.toLocaleString().slice(0, 10)} {props.retraitHoraire === 'morning' ? '(matin)' : '(après-midi)'}</p>
                            {props.validationPaiementDate ?
                                <p>{new Date(props.validationPaiementDate).toLocaleString().slice(0, 10)}</p> :
                                <button
                                    name='validationPaiementDate'
                                    className={styles.buttonBrown}
                                    disabled={!props.statutValidationDate}
                                    onClick={updateStatut}>PAIEMENT RECU</button>}
                            {props.validation.statut === 'validated' &&
                                <p>Validée ({new Date(props.validation.date).toLocaleString().slice(0, 10)})</p>}
                            {props.validation.statut === 'pending' &&
                                <div>

                                    <button
                                        name='validation'
                                        className={styles.buttonBrown}
                                        onClick={(e) => updateStatut(e, 'validated')}>VALIDER</button>

                                    <button
                                        name='validation'
                                        className={styles.buttonDiv}
                                        onClick={(e)=> updateStatut(e, 'canceled')}>REFUSER</button>

                                </div>}
                                {props.validation.statut === 'canceled' &&
                                <p>Refusée ({new Date(props.validation.date).toLocaleString().slice(0, 10)})</p>}
                        </div>
                    </div>
                    <div className={styles.detailsPanier}>
                        <h5>Détails de la commande</h5>
                        {props.panier.map((item, index) => {
                            return (
                                <div className={styles.details} key={index}>
                                    <div className={styles.image}>
                                        <img src={settings.imgMiniUrl + (item.produitImage ? item.produitImage : 'default.png')} />
                                    </div>
                                    
                                    {item.produitCondi?.type === 'unit' && <p>{`${item.produitQuantiteAchat}x ${item.produitTitre} -  ${item.produitQuantiteCondi} ${parseInt(item.produitQuantiteCondi) > 1 ? item.produitCondi?.unit?.plural : item.produitCondi?.unit?.singular}`}</p>}

                                    {item.produitCondi?.type === 'packOfUnit' && <p>{`${item.produitQuantiteAchat}x ${item.produitTitre} - ${parseInt(item.produitQuantiteAchat) > 1 ? item.produitCondi?.pack?.plural : item.produitCondi?.pack?.singular } ${item.produitQuantiteCondi} ${parseInt(item.produitQuantiteCondi) > 1 ? item.produitCondi?.unit?.plural : item.produitCondi?.unit?.singular}`}</p>}
                                    <p>{parseFloat(item.produitMontantTotal).toFixed(2)} €</p>
                                </div>)
                        })}
                    </div>
                    <div className={styles.totalCommande}>
                        <h4>TOTAL :</h4>
                        <h4>{parseFloat(props.montantTotal).toFixed(2)} €</h4>
                    </div>
                    <div className={styles.buttonDiv}>
                        <button onClick={props.close}>RETOUR</button>

                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalDetailsCommande;
import { createContext, useState } from "react";

const Data = createContext();

export const DataContext = ({ children }) => {
    const [user, setUser] = useState({
        nom: null,
        prenom: null,
        adresse: null,
        telephone: null,
        email: null,
        newsletter: false,
        role: 'anonymous',
        statutBan: null,
        token: null
    })

    const [update, setUpdate] = useState({
        password: '',
        passwordChecked: '',
        token: ''
    })

    return (
        <Data.Provider value={{
            user, setUser,
            update, setUpdate
        }}>{children}</Data.Provider>
    );
};

export default Data;
import React, { useState } from 'react';
import { Connectivity } from '../../middlewares/Connectivity';

const InfosPersos = (props) => {
    const [editUser, setEditUser] = useState({
        id: props.user._id,
        nom: props.user.nom,
        prenom: props.user.prenom,
        adresse: props.user.adresse,
        telephone: props.user.telephone,
        email: props.user.email,
        password: props.user.password,
        newsletter: props.user.newsletter,
        role: props.user.role,
        statutBan: props.user.statutBan
    })


    /**
     * Auto update input and state
     * @param {} e 
     */
    const updateInput = (e) => {
        e.preventDefault();
        setEditUser({
            ...editUser,
            [e.target.name]: e.target.value
        })
    }

    /**
     * Stop editing user's data
     */
    const cancelEdit = () => {
        setEditUser({
            id: props.user._id,
            nom: props.user.nom,
            prenom: props.user.prenom,
            adresse: props.user.adresse,
            telephone: props.user.telephone,
            email: props.user.email,
            password: props.user.password,
            newsletter: props.user.newsletter,
            role: props.user.role,
            statutBan: props.user.statutBan
        })
        props.stopEdit();
    }

    const updateUser = (e) => {
        e.preventDefault();
        Connectivity.editUser(editUser, editUser.id, props.user.token)
            .then(async (response) => {
                const result = await response.json();
                if (response.status === 200) {
                    props.setUser({
                        ...props.user,
                        ...result.userUpdated
                    })
                    props.stopEdit();
                } else {
                    console.log('resultat =>>>>', result);

                }
            })

    }


    if (props.isEditMode) {

        return (
            <article className='infos-persos'>
                <label>
                    Nom :
                    <input type='text' onChange={updateInput} name='nom' value={editUser.nom} />
                </label>
                <label>
                    Prenom :
                    <input type='text' onChange={updateInput} name='prenom' value={editUser.prenom} />
                </label>
                <label>
                    Téléphone :
                    <input type='phone' onChange={updateInput} name='telephone' value={editUser.telephone} />
                </label>
                <label>
                    Email :
                    <input type='email' onChange={updateInput} name='email' value={editUser.email} />
                </label>
                <label>
                    Mot de passe :
                    <input type='text' onChange={updateInput} name='password' value={editUser.password} />
                </label>
                <div className='btns'>

                    <button className='valider' onClick={updateUser}>VALIDER</button>
                    <button className='annuler' onClick={cancelEdit}>ANNULER</button>
                </div>

            </article>
        )
    } else {

        return (
            <article className='infos-persos'>
                <p>{props.user.nom}</p>
                <p>{props.user.prenom}</p>
                <p>{props.user.telephone}</p>
                <p>{props.user.email}</p>
                <p>*********</p>
            </article>
        );
    }
};

export default InfosPersos;
import React, { useEffect, useState } from 'react';
const settings = require('../../config/settings.json');

const DetailsProduit = (props) => {

    const [loadedImage, setLoadedImage] = useState(false);

    const [error, setError] = useState(null);
    const [produitOption, setProduitOption] = useState({
        produitId: null,
        produitTitre: null,
        produitCategorie: null,
        produitPrix: null,
        produitCondi: null,
        produitQuantiteCondi: null,
        produitQuantiteAchat: null,
        produitMontantTotal: null,
        produitImage: null
    })

    const [showQuantite, setShowQuantite] = useState(false);

    /**
     * Close quantity select options or the entire DetailsProduit component by clicking
     * outside the modal
     */
    const closeModal = (e) => {
        e.preventDefault();

        /** Used for quantite select options */
        if (e.target.className === 'options-quantite') return setShowQuantite(false);

        /** Used to prevent closing the modal if we click inside it */
        if (e.target.className !== 'modal-produit') return;

        /** Finally close the modal if we click outside it */
        props.setDetailsProduit({
            ...props.detailsProduit,
            open: false,
            produit: {}
        })
    }

    const crossClose = (e) => {
        e.preventDefault();

        props.setDetailsProduit({
            ...props.detailsProduit,
            open: false,
            produit: {}
        })
    }

    /**
     * Function to select how many quantity of the products user wants to
     * buy and add in panier
     *  
     */
    const addQuantiteAchat = (e) => {
        e.preventDefault();
        setError(null);
        if (parseInt(e.target.textContent) === produitOption.produitQuantiteAchat) {
            setProduitOption({
                ...produitOption,
                produitQuantiteAchat: null
            })
        } else {
            setProduitOption({
                ...produitOption,
                produitQuantiteAchat: parseInt(e.target.textContent)
            })
        }
        setTimeout(() => {
            setShowQuantite(false);
        }, 0)
    }




    /**
     * Function to select what conditionnement of product to add in panier
     * 
     */
    const addQuantiteCondi = (e) => {
        e.preventDefault();
        setError(null);
        let value = parseInt(e.target.textContent);
        if (e.target.className === 'checkbox' || e.target.className === 'checkbox checked') {
            value = parseInt(e.target.parentNode.textContent);
        }
        if (value === produitOption.produitQuantiteCondi) {
            setProduitOption({
                ...produitOption,
                produitQuantiteCondi: null
            })
        } else {
            setProduitOption({
                ...produitOption,
                produitQuantiteCondi: value
            })
        }
    }

    /**
     * Function to check if all produits options are selected
     * Check if produit isn't already in panier
     * and finally add produit in panier
     */

    const addPanier = (e) => {
        e.preventDefault();
        const p = produitOption

        for (let key in p) {
            if (p[key] === null) {
                if (key === 'produitQuantiteCondi') return setError('Veuillez sélectionner une option');
                if (key === 'produitQuantiteAchat') return setError('Veuillez sélectionner une quantité')
                return setError(key);
            }
        }
        const newPanier = props.panier;
        const searchProduit = (produit) => (produit.produitId === p.produitId && produit.produitQuantiteCondi === p.produitQuantiteCondi);
        const produitExist = newPanier.find(searchProduit);
        if (produitExist) {
            const indexFound = newPanier.findIndex(searchProduit);
            produitExist.produitQuantiteAchat += p.produitQuantiteAchat;
            produitExist.produitMontantTotal += p.produitMontantTotal;
            newPanier.splice(indexFound, 1, produitExist);
        } else {
            newPanier.push(p);
        }
        props.setPanier([...newPanier]);
        props.setSuccesAjout({
            ...props.succesAjout,
            open: true,
            produit: p
        })
        props.setDetailsProduit({
            ...props.detailsProduit,
            open: false,
            produit: {}
        })

    }

    /**
     * Calculate and display total amount of product when chosing quantiteCondy
     * and quantiteAchat
     */

    useEffect(() => {
        if (produitOption.produitPrix &&
            produitOption.produitQuantiteCondi &&
            produitOption.produitQuantiteAchat) {

            const p = produitOption
            const montantTotal = p.produitPrix * p.produitQuantiteAchat * p.produitQuantiteCondi
            setProduitOption({
                ...produitOption,
                produitMontantTotal: montantTotal
            })
        } else if (produitOption.produitMontantTotal !== null) {
            setProduitOption({
                ...produitOption,
                produitMontantTotal: null
            })
        }

    }, [produitOption.produitPrix, produitOption.produitQuantiteCondi, produitOption.produitQuantiteAchat])

    /**
     * Dynamically display select quantite options at good position
     */
    useEffect(() => {
        if (showQuantite) {
            const divQuantity = document.getElementById('quantity');
            const ulOptions = document.getElementById('options-list');
            const left = divQuantity.offsetLeft;
            const top = divQuantity.offsetTop;
            const width = divQuantity.offsetWidth;
            const height = divQuantity.offsetHeight;

            ulOptions.style.width = `${width}px`;
            ulOptions.style.top = `${top + height}px`;
            ulOptions.style.left = `${left}px`;
        }
    }, [showQuantite])

    /**
     * Populate the state Produit Option with props of produit displayed
     * to get ready to be added in panier
     * Also, displauy correctly allergenes' list
     */
    useEffect(() => {
               
        setProduitOption({
            ...produitOption,
            produitCategorie: props.produit.categorie,
            produitPrix: parseFloat(props.produit.prix),
            produitId: props.produit._id,
            produitTitre: props.produit.titre,
            produitCondi: props.produit.conditionnement,
            produitImage: props.produit.images[0]
        })

    }, [props])



    return (
        <div className='modal-produit' onClick={closeModal}>
            <div className='card-details-produit'>
                <div onClick={crossClose} className='cross'></div>
                <div className='card-img-produit'>
                    {!loadedImage && <img src={settings.imgMiniUrl + props.produit.images[0]} alt="" />}
                    <img style={{ display: loadedImage ? 'block' : 'none' }} onLoad={() => setLoadedImage(true)} src={settings.imgUrl + props.produit.images[0]} alt="" />
                </div>
                <div className='details-produit'>
                    <h1>{props.produit.titre.toUpperCase()}</h1>
                    <div className='textToScroll'>

                    <p className='description'>{props.produit.description}</p>
                    {props.produit.allergenes.length > 0 && <p className='allergenes'>Allergènes: {props.produit.allergenes.join(', ')}</p>}
                    {props.produit.allergenes.length === 0 && <p className='allergenes'>Aucun allergène</p>}
                    <p className='allergenes'>Conseil de conservation: {props.produit.conseilConservation}</p>
                    <p className='allergenes'>Conseil de dégustation: {props.produit.conseilDegustation}</p>
                            </div>
                    <div className='select-options'>
                        <div className='select-condi'>
                            <h4>Options disponibles :</h4>
                            {props.produit.conditionnement.type === 'packOfUnit' && <p className='condi'>
                                {parseInt(produitOption.produitQuantiteAchat) > 1 ? 
                                props.produit.conditionnement?.pack?.plural : 
                                props.produit.conditionnement?.pack?.singular}</p>}

                            {props.produit.quantiteCondi.map((value, index) => {
                                return (
                                    <label key={index} onClick={addQuantiteCondi}>
                                        <div className={produitOption.produitQuantiteCondi === parseInt(value) ? 'checkbox checked' : 'checkbox'}></div>
                                        {value}
                                    </label>
                                )
                            })}
                            <p className='condi'>
                                {parseInt(produitOption.produitQuantiteCondi) > 1 ? 
                                props.produit.conditionnement?.unit?.plural : 
                                props.produit.conditionnement?.unit?.singular}</p>
                        </div>
                        <div className='select-quantity'>
                            <h4>Quantité :</h4>
                            <div id='quantity' onClick={() => setShowQuantite(true)}>
                                <p>{produitOption.produitQuantiteAchat ? produitOption.produitQuantiteAchat : '0'}</p>
                                {showQuantite && <div
                                    className='options-quantite'
                                    onClick={closeModal}>
                                    <ul id='options-list'>
                                        <li onClick={addQuantiteAchat}>1</li>
                                        <li onClick={addQuantiteAchat}>2</li>
                                        <li onClick={addQuantiteAchat}>3</li>
                                        <li onClick={addQuantiteAchat}>4</li>
                                    </ul>
                                </div>}
                            </div>
                        </div>


                    </div>
                    <div className='ref'>

                        <p className={Boolean(error) ? 'error' : 'hidden'}>{error}</p>
                    </div>
                    <div className='montants'>
                        <h1>{produitOption.produitMontantTotal ? produitOption.produitMontantTotal.toFixed(2) : '0,00'} €</h1>
                        <p>({props.produit.prix}€/{props.produit.conditionnement?.unit?.singular})</p>
                    </div>
                    <button onClick={addPanier}>AJOUTER AU PANIER</button>
                </div>
            </div>

        </div>
    );
};

export default DetailsProduit;
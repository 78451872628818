const settings = require('../config/settings.json');
/**
 * Class Singleton with all http requests to api
 */
class Api {
    /** URL of the API */
    #apiURL

    constructor(url) {
        this.#apiURL = url
    }

    /**
     * Method to authenticate user / admin
     * @param {Object} credentials {email: String, password: String}
     * @returns Promise 
     */
    login(credentials) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        };

        return fetch(this.#apiURL + '/login', requestOptions);

    }






    /**
     * Method to fetch user's datas
     * @param {String} token authenticate token
     * @returns Promise
     */
    getUserDatas(token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            }
        };
        return fetch(this.#apiURL + '/auth/user', requestOptions);
    }





    /**
     * Method to fetch all users
     * @param {String} token authenticate token
     * @returns Promise
     */
    getAllUsers(token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            }
        };

        return fetch(this.#apiURL + '/users', requestOptions);
    }



    /**
     * Method to create a new user
     * @param {Object}} user 
     * @returns 
     */
    createUser(user) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        };

        return fetch(this.#apiURL + '/user', requestOptions);
    }


  /**
     * Method to edit user's datas
     * @param {Object}} user 
     * @param {String}} token 
     * @returns 
     */
    editUser(user, userId, token) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            },
            body: JSON.stringify(user)
        };

        return fetch(this.#apiURL + '/user/' + userId, requestOptions);
    }



    /**
     * Method to delete a user by user's ID
     * @param {String} userId 
     * @param {String} token 
     * @returns 
     */
    deleteUser(userId, token) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            },
        };

        return fetch(this.#apiURL + '/user/' + userId, requestOptions);
    }




    /**
     * Method to get produits that have status online
     * @returns 
     */
    getAvailableProduits(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return fetch(this.#apiURL + '/produits/available', requestOptions);
    }




    /**
     * Method to get all products
     * @param {String} token 
     * @returns 
     */
    getAllProduits(token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            }
        };

        return fetch(this.#apiURL + '/produits', requestOptions);
    }






    /**
     * Method to create a new produit
     */
    createProduit(token, formProduit) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Accept": '*/*',
                //  "Content-Type": 'multipart/form-data',
                "Authorization": 'Bearer ' + token
            },
            body: formProduit
        };

        return fetch(this.#apiURL + '/produit', requestOptions);

    }




    /**
     * Method to create a new produit
     */
     updateProduit(token, produitJson) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            },
            body: JSON.stringify(produitJson)
        };

        return fetch(this.#apiURL + '/produit/' + produitJson._id, requestOptions);

    }





    /**
     * Method to delete a produit by it's ID
     */
    deleteProduit(produitId, token) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            },
        };

        return fetch(this.#apiURL + '/produit/' + produitId, requestOptions);
    }






    /**
     * Method to get all commandes
     */
    getAllCommandes(token) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            }
        };

        return fetch(this.#apiURL + '/commandes', requestOptions);
    }






    /**
     * Method to create a new commande
     */
    createCommande(commande, token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Authorization": 'Bearer ' + token
            },
            body: JSON.stringify(commande)
        };

        return fetch(this.#apiURL + '/commande', requestOptions);
    }



    /**
     * Method to delete a commande by it's ID
     */
    deleteCommande(commande, token) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token
            },
            body: JSON.stringify({clientId: commande.clientId})
        };

        return fetch(this.#apiURL + '/commande/' + commande.commandeId, requestOptions);
    }

        /**
     * Method to update commande's statut by it's ID
     */
         updateStatutCommande(id, statutObject, token) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + token
                },
                body: JSON.stringify(statutObject)
            };
    
            return fetch(this.#apiURL + '/commande/statut/' + id, requestOptions);
        }

           /**
     * Method to update commande's statut by it's ID
     */
            updateStatutCommandeFromEmail(tokenAdmin, tokenCommande) {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": 'Bearer ' + tokenAdmin
                    },
                    body: JSON.stringify({tokenCommande})
                };
        
                return fetch(this.#apiURL + '/commande/statutfromemail', requestOptions);
            }
    




    /**
     * Method to send form in contact page
     */
    sendContactForm(formContact) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formContact)
        };

        return fetch(this.#apiURL + '/contact', requestOptions);
    }





 /**
     * Method to get conversation from token in URL
     */
  getConversationFromEmail(tokenInUrl, tokenAdmin) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": 'Bearer ' + tokenAdmin
        },
        body: JSON.stringify({tokenConversation: tokenInUrl})
    };

    return fetch(this.#apiURL + '/conversationwithtoken', requestOptions);
}





    /** 
     * Method to get images from carrousel
     */
     getCarrousel() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return fetch(this.#apiURL + '/slides', requestOptions);
    }


    /** 
     * Method to add image to carrousel
     */
     addImageToCarrousel(imageForm, tokenAdmin) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Accept": '*/*',
                "Authorization": 'Bearer ' + tokenAdmin
            },
            body: imageForm
        };

        return fetch(this.#apiURL + '/carrousel/62be3b9113fc5a92af3dc1de', requestOptions);
    }

    /** 
     * Method to delete image from carrousel
     */
     deleteImageInCarrousel(imageUrl, tokenAdmin) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            },
            body: JSON.stringify({slide: imageUrl})
        };

        return fetch(this.#apiURL + '/carrousel/62be3b9113fc5a92af3dc1de', requestOptions);
    }

     /** 
     * Method to get all conversations
     */
      getAllConversations(tokenAdmin) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            }
        };

        return fetch(this.#apiURL + '/conversations', requestOptions);
    }


    /** 
     * Method to update conversation
     */
     updateConversationById(message, tokenAdmin, conversationId) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            },
            body: JSON.stringify(message)
        };

        return fetch(this.#apiURL + '/conversation/' + conversationId, requestOptions);
    }

    /** 
     * Method to delete conversation by it's ID
     */
     deleteConversationById(tokenAdmin, conversationId) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            }
        };

        return fetch(this.#apiURL + '/conversation/' + conversationId, requestOptions);
    }





    /** 
     * Method to get all categories
     */
     getAllCategories() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return fetch(this.#apiURL + '/categories', requestOptions);
    }


    /**
     * Method to send form in contact page
     */
     createCategorie(categorie, tokenAdmin) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            },
            body: JSON.stringify(categorie)
        };

        return fetch(this.#apiURL + '/categorie', requestOptions);
    }


    /** 
     * Method to delete conversation by it's ID
     */
     deleteCategorieById(tokenAdmin, categorieId) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            }
        };

        return fetch(this.#apiURL + '/categorie/' + categorieId, requestOptions);
    }



    /** 
     * Method to get all allergenes
     */
     getAllAllergenes() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return fetch(this.#apiURL + '/allergenes', requestOptions);
    }


    /**
     * Method to create allergene
     */
     createAllergene(allergene, tokenAdmin) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            },
            body: JSON.stringify(allergene)
        };

        return fetch(this.#apiURL + '/allergene', requestOptions);
    }


    /** 
     * Method to delete allergene by it's ID
     */
     deleteAllergeneById(tokenAdmin, allergeneId) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            }
        };

        return fetch(this.#apiURL + '/allergene/' + allergeneId, requestOptions);
    }


    /** 
     * Method to get all quantités
     */
     getAllQuantites() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return fetch(this.#apiURL + '/quantites', requestOptions);
    }


    /**
     * Method to create a new quantite
     */
     createQuantite(quantite, tokenAdmin) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            },
            body: JSON.stringify(quantite)
        };

        return fetch(this.#apiURL + '/quantite', requestOptions);
    }


    /** 
     * Method to delete quantité by it's ID
     */
     deleteQuantiteById(tokenAdmin, quantiteId) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + tokenAdmin
            }
        };

        return fetch(this.#apiURL + '/quantite/' + quantiteId, requestOptions);
    }




    /** 
     * Method to validate email
     */
     validateEmail(tokenUrl) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tokenUrl
            }
        };

        return fetch(this.#apiURL + '/verifemail', requestOptions);
    }







     /** 
     * Method to ask new password
     */
      forgottenPassword(userEmail) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: userEmail})
        };

        return fetch(this.#apiURL + '/forgottenpassword', requestOptions);
    }







     /** 
     * Method to update user password
     */
      updateUserPassword(newPassword, token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({password: newPassword})
        };

        return fetch(this.#apiURL + '/updatepassword', requestOptions);
    }

}



/**Instantiate */
const objFreeze = Object.freeze(new Api(settings.apiUrl));


export const Connectivity = objFreeze;

import React, { useContext, useEffect, useState } from 'react';
import { Connectivity } from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';

const Categorie = (props) => {

    const { user } = useContext(Data);

    const [categories, setCategories] = useState([]);
    const [newCategorie, setNewCategorie] = useState({nom: ''});
    const [deleteMode, setDeleteMode] = useState(false);


    /**Create and add new categorie to list */
    const createCategorie = () => {
        if (newCategorie.nom.length < 3) return
        Connectivity.createCategorie(newCategorie, user.token)
            .then(async response => {
                const resJson = await response.json();
                if (response.status === 201) {
                    const categorieCreated = {_id: resJson.categorieId, nom: newCategorie.nom}
                    const newCategories = [...categories];
                    newCategories.push(categorieCreated);
                    setCategories(newCategories);
                    setNewCategorie({ nom: '' });
                }
            })
    }

    /**Delete categorie from list and DB */
    const deleteCategorie = (e,categorie) => {
        e.preventDefault();
        Connectivity.deleteCategorieById(user.token, categorie._id)
            .then( async response => {
                const result = await response.json();
                if (response.status === 200) {
                    const indexCategorie = categories.indexOf(categorie);
                    const newCategories = [...categories];
                    newCategories.splice(indexCategorie, 1);
                    setCategories(newCategories);
                    if (props.categorie === categorie.nom) props.updateWhileSelecting('categorie', '')
                }
                
            })
    }

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
        console.log('delete');
    }

useEffect(()=>{
    /** Fetch all categories from DB */
    Connectivity.getAllCategories()
        .then(async (response) => {
            const result = await response.json();
            if (response.status === 200) {
                setCategories(result.categories)
            }
            
        })

},[])
    return (
        <div className='stack introduction'>
            <h2>Catégorie</h2>
            <p>Choisissez la catégorie de votre produit.</p>
            <button 
                onClick={toggleDeleteMode}
                className='buttonDelete'
                >                
                {!deleteMode ? 'Supprimer' : "Arrêter"}
            </button>
            <div className={props.errors?.includes('categorie') ? 'flexContainer error' : 'flexContainer'}>
                {categories.map((categorie, index) => {
                    return <div 
                            className={`chip${props.categorie === categorie.nom ? ' selected' : ''}`} 
                            key={index}
                            name={'categorie'}
                            onClick={()=>props.updateWhileSelecting('categorie', categorie.nom)}
                            >{categorie.nom}{deleteMode && <div className='iconDelete' onClick={(e)=>deleteCategorie(e, categorie)}></div>}</div>
                })
            }
            </div>
            <p>Vous pouvez aussi en créer une nouvelle. Celle-ci apparaîtra automatiquement côté client pour filtrer les produits.</p>
            <input 
                type={'text'} 
                value={newCategorie.nom}
                placeholder={"Tarte de l'espace"}
                onChange={e => setNewCategorie({ nom: e.target.value })} />
                    <button 
                    disabled={newCategorie.nom.length < 3}
                    onClick={createCategorie}>CREER CATEGORIE</button>
        </div>
    );
};

export default Categorie;
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Connectivity } from '../../middlewares/Connectivity';
import Data from '../context/DataContext';
import { ClickAwayListener } from '@mui/material';


const ConnexionModal = (props) => {
    const { user, setUser } = useContext(Data);

    const [credentials, setCredentials] = useState({
        email: null,
        password: null
    })

    const [errorAuth, setErrorAuth] = useState({
        open: false,
        message: ''
    });

    /**
     * Function to update credentials state when input value change
     * @param {input} event Input 
     */
    const updateInput = (event) => {
        setCredentials({
            ...credentials,
            [event.target.name]: event.target.value
        })
    }


    /**
     * Function to connect user
     * @returns console log if incorrect credentials
     */
    const connection = (e) => {
        e.preventDefault();

        /** Regex for Email format */
        const regExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

        /** Test email with regex  */
        if (!regExp.test(credentials.email)) return setErrorAuth({
            ...errorAuth,
            open: true,
            message: 'Veuillez entrer un email valide.'
        });

        /** Verify password has at least 6 letters */
        if (credentials.password.length < 3) return setErrorAuth({
            ...errorAuth,
            open: true,
            message: 'Impossible de se connecter, veuillez vérifier vos identifiants.'
        });;

        /**Authenticate to API */
        Connectivity.login(credentials)
            .then(async (res) => {
                const response = await res.json()
                if (res.status === 404) {
                    setErrorAuth({
                        ...errorAuth,
                        open: true,
                        message: 'Impossible de se connecter, veuillez vérifier vos identifiants.'
                    });
                }
                if (res.status === 200) {
                    //Save in localStorage to persist connection
                    localStorage.setItem('token', response.token);
                    //save dats in state user
                    setUser({
                        ...user,
                        ...response.user,
                        token: response.token
                    });
                    //close modal
                    props.close()
                }
            })

    }





    return (
        <div className="fond-modal">
            <ClickAwayListener onClickAway={props.close}>

                <div className="modal-connexion">

                    <h3>Connectez-vous</h3>
                    {errorAuth.open && <p className='erreur'>{errorAuth.message}</p>}
                    <form
                        onSubmit={connection}
                        onClick={() => setErrorAuth(false)}>

                        <input
                            type="text"
                            name='email'
                            onChange={updateInput}
                            placeholder="Votre email" />

                        <input
                            type="password"
                            name='password'
                            onChange={updateInput}
                            placeholder="Votre mot de passe" />

                        <Link
                            to={'#'}
                            className='forgotPass'
                            onClick={(e) => { e.preventDefault(); props.setShowMdpOublie(true); props.close(); }}>Mot de passe oublié?</Link>



                        <div className="btns">
                            <button type='submit'>Connexion</button>
                            <button onClick={props.close}>Annuler</button>
                        </div>
                    </form>
                    <Link
                        to='/inscription'
                        className='inscription'
                        onClick={props.close}>Pas encore inscrit? Inscrivez-vous</Link>

                </div>
            </ClickAwayListener>
        </div>

    );
};

export default ConnexionModal;
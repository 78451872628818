import React, { useContext, useEffect, useState } from 'react';
import {Connectivity} from '../../middlewares/Connectivity';
import Data from '../context/DataContext';
import Map from '../map-leaflet/Map';

const Contact = () => {

    const { user } = useContext(Data);

    const [form, setForm] = useState({
        show: true,
        nom: '',
        email: '',
        message: ''
    });

    /** automatically update state with input */
    const updateInput = (event) => {
        event.preventDefault();

        setForm({
            ...form,
            [event.target.name]: event.target.value
        })

    }

    const sendMessage = (e) => {
        e.preventDefault();
        Connectivity.sendContactForm(form)
            .then(async (response) => {
                const result = await response.json();
                if (response.status === 201 || response.status === 200) {
                    setForm({
                        ...form,
                        message: result.message,
                        show: false
                    })
                }
                else {
                    console.log(result)
                }

            })
    }

    useEffect(()=>{
        if (user.role !== 'anonymous') {
            setForm({
                ...form,
                    nom: user.nom,
                    email: user.email,
                    prenom: user.prenom,
                    telephone: user.telephone,
                    userId: user._id,
                    client:{
                        nom: user.nom,
                    email: user.email,
                    prenom: user.prenom,
                    telephone: user.telephone,
                    id: user._id
                    }
            })
        }
    },[user])


    return (
        <main className='page-contact'>
            <section>
                <h2>Me Contacter</h2>
                <article>
                    <div className='icon-mail'> </div>
                    <p>contact@ml-patisserie.fr</p>
                </article>

                <article>
                    <div className='icon-phone'></div>
                    <p>06 85 25 92 42</p>
                </article>

                <article>
                    <div className='icon-location'></div>
                    <div>
                        <p>100 route de Bertranot</p>
                        <p>64370 MESPLEDE</p>
                    </div>
                </article>

                <Map />
                
            </section>
            {form.show && <section>
                <h2>Formulaire de Contact</h2>
                <form onSubmit={sendMessage}>
                    <input
                        name="nom"
                        type="text"
                        placeholder="Votre nom"
                        value={form.nom}
                        onChange={updateInput}
                        required />

                    <input
                        name="email"
                        type="email"
                        placeholder="Votre adresse e-mail"
                        value={form.email}
                        onChange={updateInput}
                        required />

                    <textarea
                        name="message"
                        maxLength={400}
                        placeholder="Tapez votre message"
                        value={form.message}
                        onChange={updateInput}
                        required></textarea>

                    <input
                        type="submit"
                        value="ENVOYER" />

                </form>
            </section>}
            {!form.show && <section>
                <h2>Succès</h2>
                <p>{form.message}</p>
            </section>}
        </main>
    );
};

export default Contact;
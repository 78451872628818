import { Button, MobileStepper } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Connectivity } from '../../../middlewares/Connectivity';
import Admin from '../../context/AdminContext';
import Data from '../../context/DataContext';

const StepperDown = (props) => {

  const { user } = useContext(Data);
  const { editingProduit } = useContext(Admin);

  const navigate = useNavigate();

  const verifyAndNavigate = () => {
   const errors = props.verifyFields(editingProduit.steps[props.activeStep].fields);
   if (errors.length > 0) return;
   props.handleNext();
  }

  const createOrUpdateProduit = () => {
    const formData = new FormData();
    formData.append("titre", props.newProduit.titre);
    formData.append("description", props.newProduit.description);
    formData.append("statutPublication", props.newProduit.statutPublication);
    formData.append("categorie", props.newProduit.categorie);
    formData.append("prix", props.newProduit.prix);
    formData.append("conditionnement", JSON.stringify(props.newProduit.conditionnement));
    formData.append("conseilDegustation", props.newProduit.conseilDegustation);
    formData.append("conseilConservation", props.newProduit.conseilConservation);
    props.newProduit.allergenes.forEach(element => {
      formData.append('allergenes', element);
    });
    props.newProduit.quantiteCondi.forEach(element => {
      formData.append('quantiteCondi', element);
    })
    for (let i = 0; i < props.newProduit.newImages.length; i++) {
      formData.append('files', props.newProduit.newImages[i]);
    }



    if (props.mode === "edition") {

        Connectivity.updateProduit(user.token, props.newProduit)
        .then(async (response) => {
            const result = await response.json();
            if (response.status === 200) {
              props.setNewProduit({
                titre: '',
                description: '',
                prix: '',
                categorie: "",
                conditionnement: {
                    type: '',
                    unit: {
                        singular: '',
                        plural: ''
                    },
                    pack: {
                        singular: '',
                        plural: ''
                    }
                },
                conseilDegustation: '',
                conseilConservation: '',
                quantiteCondi: [],
                allergenes: [],
                statutPublication: true,
                images: [],
                newImages: []
            });
            navigate('/admin/produits');
            }

        })

    } else {

    Connectivity.createProduit(user.token, formData)
      .then(async (response) => {
        const result = await response.json();
        if (response.status === 201) {
          props.setNewProduit({
            titre: '',
            description: '',
            prix: '',
            categorie: "",
            conditionnement: {
                type: '',
                unit: {
                    singular: '',
                    plural: ''
                },
                pack: {
                    singular: '',
                    plural: ''
                }
            },
            conseilDegustation: '',
            conseilConservation: '',
            quantiteCondi: [],
            allergenes: [],
            statutPublication: true,
            images: [],
            newImages: []
        });
        navigate('/admin/produits');
        }

      })
     }
  }

  return (
    <div className={props.openMenu ? 'stepperContainer' : 'stepperContainer closed'}>

      <MobileStepper
        variant="progress"
        steps={props.steps}
        position="static"
        activeStep={props.activeStep}
        sx={{
          maxWidth: '800px',
          height: '80px',
          margin: "auto",
          backgroundColor: '#fcfcfc'
        }}
        nextButton={
          (!props.formIsCompleted || props.activeStep !== props.steps - 1) ?
            <Button
              size="small"
              onClick={verifyAndNavigate}
              disabled={props.activeStep === props.steps - 1}
            >Suivant</Button>
            : <Button
              size="small"
              onClick={createOrUpdateProduit}
            >Enregistrer</Button>
        }
        backButton={
          <Button size="small" onClick={props.handleBack} disabled={props.activeStep === 0}>
            Précédent
          </Button>
        }
      />
    </div>
  );
};

export default StepperDown;
import { ClickAwayListener } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Connectivity } from '../../middlewares/Connectivity';

const MdpOublieModal = (props) => {
    const [messageEvent, setMessageEvent] = useState({
        type: 'error',
        open: false,
        message:''
    })
    const [email, setEmail] = useState('');
    const [state, setState] = useState({
        disabled: false
    })

    const updateInput = (e) => {
        setEmail(e.target.value)
    }

    const sendEmail = (e) => {
        setState({
            ...state,
            disabled: true
        })
        e.preventDefault();

        /** Regex for Email format */
        const regExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        
        /** Test email with regex  */
        if (!regExp.test(email)) {
            setState({
                ...state,
                disabled: false
            })
            return setMessageEvent({
            ...messageEvent,
            type: 'error',
            open: true,
            message: 'Veuillez entrer un email valide.'
        });
    }


        Connectivity.forgottenPassword(email)
        .then(async reponse => {
            const result = await reponse.json();
            if (reponse.status === 200) {
                setState({
                    ...state,
                    disabled: false
                })
                setMessageEvent({
                    ...messageEvent,
                    type:'success',
                    open: true,
                    message: 'Le lien pour réinitialiser votre mot de passe a été envoyé.'
                });
                setEmail('')
            }
        })
    }

    useEffect(()=>{
        if (messageEvent.type === 'success') {
            setTimeout(()=>{
                props.close();
            },5000)
        }
    },[messageEvent.type])


        return (
        <div className="fond-modal">
            <ClickAwayListener onClickAway={props.close}>

            <div className="modal-connexion">

                <h3>Renseignez votre email</h3>
                    {messageEvent.open && <p className={messageEvent.type}>{messageEvent.message}</p>}
                <form 
                    onSubmit={sendEmail} 
                    onClick={()=> setMessageEvent({
                        ...messageEvent,
                        open: false
                    })}>

                    <input
                        type="text"
                        name='email'
                        onChange={updateInput}
                        placeholder="Votre email"
                        value={email} />

                   

                    <div className="btns">
                        <button type='submit' disabled={state.disabled}>ENVOYER</button>
                        <button onClick={props.close}>Annuler</button>
                    </div>
                </form>
                   

            </div>
                        </ClickAwayListener>
        </div>

    );
};

export default MdpOublieModal;
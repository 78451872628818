import React from 'react';
import  settings  from '../../../config/settings.json';

const Recapitulatif = (props) => {
    const [quantitySeleted, setQuantitySeleted] = React.useState('');
    return (
        <div className='stack introduction'>
            <h2>Récapitulatif</h2>
            <div className='card-recap-produit'>

                {(props.produit.newImages[0]?.name) && <img
                    style={{ display: 'block' }}
                    src={URL.createObjectURL(props.produit.newImages[0])}
                    alt="newImage" />}
                
                {(props.produit.images.length > 0) && <img
                    style={{ display: 'block' }}
                    src={settings.imgMiniUrl + props.produit.images[0]}
                    alt="newImage" />}

                {(!props.produit.newImages[0]?.name && props.produit.images.length === 0) && <p>Veuillez sélectionner une image pour votre produit</p>}

                <div className='details-produit'>
                    <h1>{props.produit.titre.toUpperCase()}</h1>
                    <div className='textToScroll'>

                        <p className='description'>{props.produit.description}</p>
                        <div className='paragraphe'>
                            <h4>Allergènes:</h4>
                            <p>{props.produit.allergenes.join(', ')}</p>
                        </div>
                        <div className='paragraphe'>
                        <h4>Conseil de conservation: </h4>
                        <p>{props.produit.conseilConservation}</p>
                        </div>
                        <div className='paragraphe'>
                        <h4>Conseil de dégustation: </h4>
                        <p>{props.produit.conseilDegustation}</p>
                        </div>
                    </div>
                    <div className='select-options'>
                            <h4>Options disponibles :</h4>
                        <div className='select-condi'>
                            {props.produit.conditionnement.type === 'packOfUnit' && <p>{props.produit.conditionnement.pack.singular}</p>}
                            {props.produit.quantiteCondi.map((value, index) => {
                                return (
                                    <label key={index} onClick={() => setQuantitySeleted(value)}>
                                        <div className={quantitySeleted === value ? 'checkbox checked' : 'checkbox'}></div>
                                        {value}
                                    </label>
                                )
                            })}
                            {quantitySeleted < 2 && <p className='condi'>{props.produit.conditionnement.unit.singular}</p>}
                            {quantitySeleted > 1 && <p className='condi'>{props.produit.conditionnement.unit.plural}</p>}

                        </div>
                        <div className='select-quantity'>
                            <h4>Quantité :</h4>
                            <div id='quantity'>
                                <p>{'1'}</p>
                            </div>
                        </div>


                    </div>

                    <div className='montants'>
                        <h1>{parseFloat(quantitySeleted * props.produit.prix).toFixed(2)} €</h1>
                        <p>({props.produit.prix}€/{props.produit.conditionnement.unit.singular})</p>
                    </div>
                    <h4>Statut :</h4>
                    <button 
                        name='statutPublication'
                        onClick={props.updateInput}
                    >{props.produit.statutPublication ? "EN LIGNE" : "HORS LIGNE"}</button>
                </div>
            </div>
        </div>
    );
};

export default Recapitulatif;
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Connectivity } from '../../middlewares/Connectivity';
import Data from '../context/DataContext';
import InfosPersos from '../page-profil/InfosPersos';
import ItemCommande from '../page-profil/ItemCommande';

const Profil = () => {

    const {user, setUser} = useContext(Data);
    const [isEditMode, setIsEditMode] = useState(false);
    const redirect = useNavigate();

     /** 
     * Connect user if userId and token are valids on app loading 
     */
      useEffect(() => {
        /** Get Token and UserId from localStorage */
        const token = localStorage.getItem('token');

        /** If there are token and userId */
        if (token) {

            /** Use them to fetch user's datas */
            Connectivity.getUserDatas(token)
                .then(async (res) => {
                    const response = await res.json();

                    /** If token and userId respond with success status */
                    if (res.status === 200) {

                        /** set user's datas and token to State */
                        setUser({
                            ...user,
                            ...response.user,
                            token: token
                        });

                        /**Otherwise... */
                    } else {
                        //TODO Handle error
                        console.log(response);
                        redirect('/')
                    }
                })
        }

    }, [])



    return (
        <main className="page-profil">
        <h3>MES COORDONNEES</h3>
        <section className="profil_infos">
            <h4>Mes informations personnelles:</h4>
            {!isEditMode && <div className='icon-edit' onClick={()=> setIsEditMode(true)}></div>}
            <InfosPersos 
                user={user}
                setUser={setUser}
                isEditMode={isEditMode}
                stopEdit={()=> setIsEditMode(false)}
                 />
        </section>
        <section className="profil_commande">
            <h4>Mes dernières commandes:</h4>
            
            <article className='liste-commandes'>
                {(user.commandes && user.commandes.length > 0) && user.commandes.map((commande, index) => {
                     const details = []
                     console.log(commande);
                     commande.panier.forEach(item => {
                        let formatted;
                        if (item.produitCondi?.type === 'unit'){
                            formatted = `${item.produitQuantiteAchat}x ${item.produitTitre} -  ${item.produitQuantiteCondi} ${parseInt(item.produitQuantiteCondi) > 1 ? item.produitCondi?.unit?.plural : item.produitCondi?.unit?.singular}`;
                        }
                        if (item.produitCondi?.type === 'packOfUnit'){
                            formatted = `${item.produitQuantiteAchat}x ${item.produitTitre} - ${parseInt(item.produitQuantiteAchat) > 1 ? item.produitCondi?.pack?.plural : item.produitCondi?.pack?.singular } ${item.produitQuantiteCondi} ${parseInt(item.produitQuantiteCondi) > 1 ? item.produitCondi?.unit?.plural : item.produitCondi?.unit?.singular}`;
                        }
                         details.push(formatted);
                     });
                     let statut = 'En attente';
                     if (commande.validation.statut === 'validated') statut = "Validée";
                     if (commande.validation.statut === 'canceled') statut = "Annulée";
                    return <ItemCommande 
                                key={index}
                                creationDate={commande.creationDate}
                                details={details}
                                image={commande.panier[0].produitImage}
                                montantTotal={commande.montantTotal}
                                statut={statut}
                            />
                })}
            </article>
        </section>
    </main>
    );
};

export default Profil;
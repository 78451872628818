import React, { useEffect, useState } from 'react';
// import slides from '../../config/datas/slides.json';
import settings from '../../config/settings.json';
import { Connectivity } from '../../middlewares/Connectivity';

const Slider = () => {
    const [slide, setSlide] = useState(0);
    const [slides, setSlides] = useState([]);

    
    const moveSlides = (carrousel) => {
        let length = carrousel.length;
        const imgWidth = 100 / length;
        let i = 0;
        return setInterval(() => {
          if ((i + imgWidth) >= 100){
              i = 0;
          } else{
              i += imgWidth;
          }
         setSlide(i)
        }, 6000);

    }
    
    useEffect(()=> {

       Connectivity.getCarrousel()
       .then(async (response) => {
           const result = await response.json();
           if (response.status === 200) {
               setSlides(result.slides[0].slides)
           }

       })
    },[]);

    useEffect(()=>{
        if (slides.length > 0){
            const intervalNumber = moveSlides(slides)

            return () => {
                clearInterval(intervalNumber)
            }
        }
    },[slides])
    

    return (
        <section>
            <article className='containerSlide' style={{transform: `translateX(-${slide}%)`}}>
            {
                slides && slides.map((value, index) => {
                    return (
                            <img key={index} src={settings.imgSlider + value} alt={`pâtisseries ${index}`} />
                    )
                })
            }

            </article>
        </section>
    );
};

export default Slider;
import React from 'react';

const AlertModal = (props) => {
    return (
        <div className="fondModal">
            <div className="modal-alert">

                <h3>ATTENTION</h3>
                <p>Etes-vous sûr de vouloir supprimer?</p>
                <div className="btns">
                    <button className='alert' onClick={props.action} >SUPPRIMER</button>
                    <button onClick={props.close}>ANNULER</button>
                </div>

            </div>
        </div>

    );
};

export default AlertModal;
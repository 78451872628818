import React, { useEffect } from 'react';
import styles from '../../../assets/scss/admin/ItemConversationAdmin.module.scss';


const ItemConversation = (props) => {

    

    return (
        <div className={props.statutRead ? styles.itemConversation : styles.itemConversationToRead} onClick={props.showConv}>
            <div className={styles.dateConversation}>
                <p>{new Intl.DateTimeFormat('fr-FR', {weekday: 'long'}).format(props.creationDate)}</p>
                <p>{props.creationDate.getDate()}</p>
                <p>{new Intl.DateTimeFormat('fr-FR', {month: 'long'}).format(props.creationDate)}</p>
                <p>{props.creationDate.getFullYear()}</p>
            </div>
            <h3>{props.nomComplet}</h3>
            <p className={styles.apercu}>{props.message.slice(0, 70)} ...</p>
            <div className={styles.iconDelete} onClick={props.delete}></div>
        </div>
    );
};

export default ItemConversation;
import React from 'react';

const Introduction = (props) => {
    return (
        <div className='stack introduction'>
            <h2>INTRODUCTION</h2>
            <p> Bienvenue dans la {props.editing ? 'modification' : 'création'} de votre produit ! </p>
            <p>Toutes les informations renseignées dans cette section vont constituer le produit que les clients vont venir acheter. </p>
            <p>Avant de démarrer le processus de création, il est préférable que toutes les informations, photos,... soient préparées et directement accessibles.</p>
            <button onClick={props.next}>Créer produit</button>
        </div>
    );
};

export default Introduction;
import React, { useEffect } from 'react';

const ItemClient = (props) => {
    
    return (
        <article className={!props.odd ? 'item-client' : 'item-client darker'}>
            <p className='medium'>{new Date(props.creationDate).toLocaleString().slice(0,10)}</p>
            <p>{props.prenom + ' ' + props.nom}</p>
            <p className='medium'>{props.telephone}</p>
            <p className='large'>{props.email}</p>
               <div className='adresse'>
               <p>{props.adresse.ligne1}</p>
               {/* <p>{props.adresse.ligne2}</p> */}
               <p>{props.adresse.codePostal} {props.adresse.ville}</p>
               </div>
           
            <p className='medium'>{props.statut}</p>
            <div className='small'>
                <div className='admin-icon-edit' onClick={props.edit}></div>
                <div className='icon-delete' onClick={props.delete}></div>
            </div>
        </article>
    );
};

export default ItemClient;
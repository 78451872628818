import { createContext, useContext, useEffect, useState } from "react";
import { Connectivity } from "../../middlewares/Connectivity";
import Data from "./DataContext";

const Admin = createContext();

export const AdminContext = ({ children }) => {

    const {user} = useContext(Data);

    const [users, setUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [conversations, setConversations] = useState([]);



    const getConversations = () => {
        Connectivity.getAllConversations(user.token)
        .then(async result => {
            const response = await result.json();
            
            if (result.status === 200) {
                setConversations(response.conversations);
            } else {
                console.log(response)
            }
        })
    }

    const editingProduit = {
        steps: [
            {
                name: "Introduction",
                required: false,
                fields: []
            },
            {
                name: "Présentation",
                required: true,
                fields: [
                    {
                        name: "titre",
                        required: true
                    },
                    {
                        name: "description",
                        required: true
                    }
                ]
            },
            {
                name: "Photos",
                required: true,
                fields: [
                    {
                        name: "images",
                        required: true
                    },
                ]
            },
            {
                name: "Catégorie",
                required: true,
                fields: [
                    {
                        name: "categorie",
                        required: true
                    }
                ]
            },
            {
                name: "Conditionnement",
                required: true,
                fields: [
                    {
                        name: "conditionnement",
                        required: true
                    }
                ]
            },
            {
                name: "Conditionnement (suite)",
                required: true,
                fields: [
                    {
                        name: "quantiteCondi",
                        required: true
                    },
                    {
                        name: "prix",
                        required: true
                    }
                ]
            },
            {
                name: "Allergènes",
                required: true,
                fields: [
                    {
                        name: "allergenes",
                        required: true
                    }
                ]
            },
            {
                name: "Conseils",
                required: true,
                fields: [
                    {
                        name: "conseilDegustation",
                        required: true
                    },
                    {
                        name: "conseilConservation",
                        required: true
                    }
                ]
            },
            {
                name: "Récapitulatif",
                required: false,
                fields:[
                    {
                        name: "statutPublication",
                        required: true
                    }
                ],
            },
        ]
    }


  useEffect(()=> {
        if (user.token){
            getConversations();
           
        } 
    },[user.token])

    return (
        <Admin.Provider value={{
            conversations, setConversations,
            editingProduit,
            getConversations,
            orders, setOrders,
            products, setProducts,
            users, setUsers
        }}>{children}</Admin.Provider>
    );
};

export default Admin;
import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Connectivity } from '../../middlewares/Connectivity';
import Data from '../context/DataContext';
import styles from '../../assets/scss/verifemail.module.scss';


const UpdatePassword = () => {

    const {user, setUser, update, setUpdate} = useContext(Data);

    const location = useLocation();

    const navigate = useNavigate();

    const [request, setRequest] = useState({
        status: null,
        message: 'Réinitialiser votre mot de passe'
    })

    const [error, setError] = useState({
        open: false,
        message: ''
    })

    const updateInput = (e) => {
        e.preventDefault();
        setError({...error, open: false})
        setUpdate({
            ...update,
            [e.target.name]: e.target.value
        })
    }


    const checkFormAndSend = (e) => {
        e.preventDefault();
        if (update.password.length < 8) {
            return setError({
                ...error, 
                open: true, 
                message: 'Le mot de passe doit contenir au minimum 8 caractères'
            });
        }
        if (update.password !== update.passwordChecked) {
            return setError({
                    ...error, 
                    open: true, 
                    message: 'Les mots de passe ne correspondent pas'
                });
        }
        setRequest({
            ...request,
            status: 'pending'
        })
        Connectivity.updateUserPassword(update.password, update.token)
        .then(async response => {
            const result = await response.json();
            if (response.status === 200) {
                setRequest({
                    ...request,
                    status: 'success',
                    message: '✅ Mot de passe modifié avec succès! Vous allez automatiquement être redirigé.'
                })
                setUser({
                    ...user,
                    ...result.user
                })
            }

            
        })
        .catch((error) =>{
            setRequest({
                ...request,
                status: null
            })
            setError({
                ...error,
                open: true,
                message: "Une erreur s'est produite, veuillez réessayer plus tard."
            })

        })
    }

    useEffect(()=>{
        const token = location.search.split('token=')[1];
        if (token && token.length > 0 && update.token.length === 0){
            setUpdate({
                ...update,
                token: token
            })
            navigate('/passoublie');
        }


        if (!token && update.token.length === 0) {
            navigate('/');
        }
    },[])

    useEffect(()=> {
        if (request.status === 'success') {
            setTimeout(() => {navigate('/')}, 3000);
        }
    },[request.status])



    return (
        <main className={styles.verif}>

         <section className={styles.verifSection}>
            <h2>{request.message}</h2>
            {request.status === 'pending' && <CircularProgress />}
            {request.status === 'success' && <p>{request.message}</p>}
            {!request.status && (
                <form 
                    onSubmit={checkFormAndSend}
                    className={styles.updateForm}
                >
                {error.open && <p>{error.message}</p>}
                    <label>
                        Nouveau mot de passe :
                        <input 
                            name='password' 
                            type={'password'} 
                            onChange={updateInput}
                            value={update.password} />
                    </label>
                    <label>
                        Confirmez votre nouveau mot de passe :
                        <input 
                            name='passwordChecked' 
                            type={'password'} 
                            onChange={updateInput}
                            value={update.passwordChecked} />
                    </label>
                    <button type='submit'>ENVOYER</button>
                </form>
            )}

          
        </section>
    </main>
    );
};

export default UpdatePassword;
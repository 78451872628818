import React from 'react';

const APropos = () => {
    return (
        <main className='page-apropos'>
            <section className="reverse">
                <article>
                    <p>L’odeur délicate et reconnaissable entre toutes de la tarte sortant du four...</p>
                    <p>Le tour de main et les recettes que nos mamans tenaient de nos grands-mères...</p>
                    <p>Des moments de convivialité et les voix de nos frères et sœurs qui raisonnent autour du gâteau
                        d’anniversaire....</p>
                    <p>Voilà des souvenirs que nous chérissons tous et qui pour ma part m’ont tout naturellement conduite à la
                        pâtisserie.</p>
                    <p>Animée par la passion de mon métier, j’espère que mes desserts, réalisés de façon artisanale avec des
                        ingrédients soigneusement sélectionnés, pourront vous procurer l’émotion de ces moments inoubliables.</p>
                </article>
                <img src={process.env.PUBLIC_URL + "/images/apropos1.jpg"} alt="" />
            </section>
            <section>
                <img src={process.env.PUBLIC_URL + "/images/apropos2.jpg"} alt="" />
                    <article>
                        <h2>Délais de préparation</h2>
                        <p>Je vous propose une sélection de pâtisseries artisanales réalisées exclusivement sur commande
                            afin de vous garantir la fraîcheur et la saveur des produits. Pour cette raison, un délai d'au minimum
                            72h est nécessaire à la réalisation de vos desserts.</p>
                        <h2>Réception des commandes</h2>
                        <p>La réception de vos commandes pourra se faire directement au point de retrait situé au 100 route de Bertranot 64370 Mesplède</p>
                        <p>La livraison à domicile est possible selon disponibilités. Merci de bien vouloir formuler votre demande en même temps que votre commande via le formulaire de contact ou par téléphone</p>
                    </article>
            </section>
        </main>
    );
};

export default APropos;
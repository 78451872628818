import React from 'react';

const Conseils = (props) => {
    return (
        <div className='stack introduction'>
        <h2>Conseils</h2>
        <div className='stack'>
            <p>Décrivez en quelques mots la meilleure façon de conserver votre produit.</p>                
            <textarea
                placeholder="A l'abri de la lumière"
                onChange={props.updateInput}
                name='conseilConservation'
                value={props.conseilConservation}
                className={props.errors.includes('conseilConservation') ? 'error' : ''}
            />
        </div>
        <div className='stack'>
            <p>Décrivez brièvement la meilleure façon manière de déguster votre produit.</p>
            <textarea
                placeholder='Sorti du réfrigérateur 15 min avant dégustation'
                name='conseilDegustation'
                onChange={props.updateInput}
                value={props.conseilDegustation}
                className={props.errors.includes('conseilDegustation') ? 'error' : ''}
            />
        </div>
    </div>
    );
};

export default Conseils;
import React, { useEffect, useState } from 'react';
const settings = require('../../../config/settings.json')

const ItemCommandeAdmin = (props) => {

    const [validation, setValidation] = useState({
        statut: 'En attente de validation',
        date: '...'
    })

    useEffect(()=>{
        if (props.validation.statut === 'validated'){
            setValidation({
                ...validation,
                statut: 'Acceptée',
                date: new Date(props.validation.date).toLocaleDateString()
            })
        }
        if (props.validation.statut === 'canceled'){
            setValidation({
                ...validation,
                statut: 'Refusée',
                date: new Date(props.validation.date).toLocaleDateString()
            })
        }

    },[props.validation])

    return (
        <div className={!props.odd ? 'item-commande' : 'item-commande darker'}>
            <p className='date'>{new Date(props.retraitDate).toLocaleString().slice(0, 10)} ({props.retraitHoraire})</p>
            <p className='date'>{new Date(props.creationDate).toLocaleString().slice(0, 10)}</p>
            <p className='clientName'>{props.clientName}</p>
            <div className='details'>
                <div className='image'>
                    <img src={settings.imgMiniUrl + (props.image ? props.image : 'default.png')} />
                </div>
                {props.details.map((value, index) => {
                    return <p key={index}>{value}</p>
                })}
            </div>
            <p className='montantTotal'>{props.montantTotal.toFixed(2)} €</p>
            <p className='validationStatut'>{validation.statut} ({validation.date})</p>
            <div className='icons'>
            <button onClick={props.showDetails} className='button-details'>DETAILS</button>
                <div className='icon-delete' onClick={props.delete}></div>
            </div>
        </div>
    );

};

export default ItemCommandeAdmin;
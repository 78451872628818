import React, { useEffect, useState } from 'react';
const settings = require('../../config/settings.json');

const ItemProduitCompact = (props) => {

    const formattedDescription = () => {
        if (props.description.length > 30){
            const description = props.description.slice(0, 80) + '...';
            return description
        }
        return props.description
    }

    

    return (
        <article className='produit-compact' >

            <div className='card-img'>
                <img src={settings.imgMiniUrl + props.img} alt={props.titre} />
            </div>

            <div className='produits-infos'>
                <h3>{props.titre.toUpperCase()}</h3>
                <p>{formattedDescription()}</p>
                <h4>{props.prix} €/{props.conditionnement?.unit?.singular}</h4>
                <button onClick={props.openModalProduit}>CHOISIR OPTIONS</button>
            </div>

        </article>
    );
};

export default ItemProduitCompact;
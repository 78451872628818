import React, { useEffect, useRef, useState } from 'react';

const UploadImage = (props) => {

    const [previewUrl, setPreviewUrl] = useState(null);
    const inputFile = useRef(null);


    useEffect(()=>{
        if (props.image?.name){
            const objURL = URL.createObjectURL(props.image);
            setPreviewUrl(objURL);
        } 
        if (props.imageUrl){
            setPreviewUrl(props.imageUrl);
        }

        if (!props.image?.name && !props.imageUrl){
            setPreviewUrl(null);
        }
    },[props.image, props.imageUrl])

    return (
        <div className='add-preview-images'>

                        {!previewUrl && <div className={ props.errors?.includes('images') ? 'addImage error' : 'addImage'} onClick={() => inputFile.current.click()} ></div>}
                        
                        {previewUrl && <div className='preview-images'>
                            <img src={previewUrl} alt="Aperçu" />                            
                        </div>}

                        <input 
                        ref={inputFile}
                            type='file' 
                            name='newImages'
                            onChange={props.updateInput} 
                            // multiple
                            hidden
                            accept="image/*" />
                    </div>
    );
};

export default UploadImage;
import React from 'react';
import 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const Map = () => {
    return (
        
      <MapContainer center={[43.487510, -0.664391]} zoom={12}>
      <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[43.487510, -0.664391]}>
         
        </Marker>
  </MapContainer>

    );
};

export default Map;
import React, { useContext, useEffect, useRef, useState } from 'react';
import {Connectivity} from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';
import ModalCategories from './ModalCategories';
import styles from '../../../assets/scss/admin/ModalCategories.module.scss'
const settings = require('../../../config/settings.json');



const ModalCreationProduit = (props) => {

    const { user } = useContext(Data);

    const [newProduit, setNewProduit] = useState({
        titre: '',
        description: '',
        prix: '',
        categorie: "",
        typeCondi: '',
        conseilDegustation: '',
        conseilConservation: '',
        quantiteCondi: [],
        allergenes: [],
        statutPublication: true,
        images: null
    })


    const [categories, setCategories] = useState([]);
    const [inputCategories, setInputCategories] = useState(null);



    const [files, setFiles] = useState([]);
    
    const [previewsUrl, setPreviewsUrl] = useState([]);

    const formulaire = useRef(null);
    const inputFile = useRef(null);

    /** automatically update state with input */
    const updateInput = (event) => {
        if (event.target.name === "statutPublication") {
            setNewProduit({
                ...newProduit,
                statutPublication: !newProduit.statutPublication
            })

         } else if(event.target.name === "files"){
            setFiles(Array.from(event.target.files))

        } else if(event.target.name === "description"){
            setNewProduit({
                ...newProduit,
                [event.target.name]: event.target.value
            })

        } else {

            setNewProduit({
                ...newProduit,
                [event.target.name]: event.target.value.toLowerCase()
            })
        }
    }

    /** add new values to array quantité or allergènes */
    const addValueArray = (e) => {
        e.preventDefault();
        if (e.target.previousSibling.value.length === 0) return
        
        if (e.target.previousSibling.name === 'allergenes') {
            /** Check if value already exists in array */
            if (newProduit['allergenes'].includes(e.target.previousSibling.value.toLowerCase())) return
            const allergenesArray = newProduit.allergenes;
            allergenesArray.push(e.target.previousSibling.value.toLowerCase());
            allergenesArray.sort();
            setNewProduit({
                ...newProduit,
                allergenes: allergenesArray
            })
            e.target.previousSibling.value = '';
        }
        if (e.target.previousSibling.name === 'quantites') {
            /** Check if value already exists in array */
            if (newProduit['quantiteCondi'].includes(e.target.previousSibling.value)) return
            if (e.target.previousSibling.value <= 0 ) return
            const quantitesArray = newProduit.quantiteCondi;
            quantitesArray.push(e.target.previousSibling.value);
            quantitesArray.sort((a, b) => {return a - b});
            setNewProduit({
                ...newProduit,
                quantiteCondi: quantitesArray
            })
            e.target.previousSibling.value = '';
        }
    }
    // useEffect(()=>{
    //     console.log(newProduit);
    // },[newProduit])


    const checkFormAndSend = (form) => {

        if (newProduit.quantiteCondi.length < 1) console.log('choisissez une quantité');
        
        const formData = new FormData(form.current);
        formData.append('statutPublication', newProduit.statutPublication);
        formData.delete('allergenes');
        formData.delete('quantiteCondi');
        newProduit.allergenes.forEach(element => {
            formData.append('allergenes', element);            
        });
        newProduit.quantiteCondi.forEach(element => {
            formData.append('quantiteCondi', element);
        })


        if (props.modalCreate.produit) {

            Connectivity.updateProduit(user.token, newProduit)
            .then(async (response) => {
                const result = await response.json();
                if (response.status === 200) {
                    const newProduits =[...props.produits];
                    const index = newProduits.indexOf(props.modalCreate.produit);

                    newProduits.splice(index, 1, newProduit);
                    props.setProduits(newProduits);
                    props.close()
                }
                
            })

        } else {

            Connectivity.createProduit(user.token, formData)
            .then(async (response) => {
                const result = await response.json();
                if (response.status === 201) {
                    const newProduits = props.produits;
                    newProduits.unshift(result.createdProduit);
                    props.setProduits(newProduits);
                    props.close()
                }
                
            })
        }
    }
    

useEffect(()=>{
    if (files.length === 0) return
    const array = []
    files.forEach((file, index) => {
        const objURL = URL.createObjectURL(file);
        array.push(objURL);
    })
    setPreviewsUrl(array)
},[files])


  useEffect(()=>{
    if (props.modalCreate.produit) {
        const produit= props.modalCreate.produit;
        setNewProduit({
            _id: produit._id,
            titre: produit.titre,
        description: produit.description,
        prix: produit.prix,
        categorie: produit.categorie,
        typeCondi: produit.typeCondi,
        conseilDegustation: produit.conseilDegustation,
        conseilConservation: produit.conseilConservation,
        quantiteCondi: produit.quantiteCondi,
        allergenes: produit.allergenes,
        statutPublication: produit.statutPublication,
        images: produit.images
        })
    }
  }, [props.modalCreate])

  useEffect(()=>{
        Connectivity.getAllCategories()
        .then(async (response) => {
            const result = await response.json();
            if (response.status === 200) {
                setCategories(result.categories)
            }
            
        })
  },[])


    return (
        <div className='fond-modal'>
            <div className="modal-creation-produit">
                <form ref={formulaire} onSubmit={(e) => {e.preventDefault(); checkFormAndSend(formulaire)}} encType="multipart/form-data">
                    <div className='add-preview-images'>
                        {files.length === 0 && !newProduit.images && <div className='addImage' onClick={() => inputFile.current.click()} ></div>}
                        {previewsUrl.length > 0 && !newProduit.images && <div className='preview-images'>
                            {previewsUrl.map((value, index) =>{
                                
                                return  <img key={index} className={index !== 0 ? 'mini' : ''} src={value} alt="Preview" />
                            })}
                        </div>}
                        {newProduit.images && <div className='preview-images'>
                            <img src={settings.imgMiniUrl + newProduit.images[0]} alt="Image" />
                            
                        </div>}
                        <input 
                        ref={inputFile}
                            type='file' 
                            name='files'
                            onChange={updateInput} 
                            multiple
                            hidden
                            accept="image/*" />
                    </div>

                    <div className='titre-description'>
                        <p>Titre du produit</p>
                        <input onChange={updateInput} name='titre' type='text' value={newProduit.titre} required />
                        <p>Description</p>
                        <textarea onChange={updateInput} name='description' value={newProduit.description} required></textarea>
                    </div>
                    <label 
                        className={'referedLabel'}
                        >
                        Catégorie
                        <input 
                            onChange={updateInput} 
                            readOnly 
                            name='categorie' 
                            type='text' 
                            value={newProduit.categorie}
                            onClick={(e)=> setInputCategories({x: e.target.offsetLeft, y: e.target.offsetTop, height: e.target.offsetHeight, width: e.target.offsetWidth})}
                            required />

                     {Boolean(inputCategories) && 
                            <ModalCategories 
                                anchor={inputCategories} 
                                close={()=> setInputCategories(null)} 
                                selectCat={(categorieSelected) => setNewProduit({...newProduit, categorie: categorieSelected})}
                                categories={categories}
                                setCategories={setCategories} />}
                    </label>
                    <label>
                        Type de conditionnement
                        <input onChange={updateInput} name='typeCondi' type='text' value={newProduit.typeCondi} required />
                    </label>
                    <label>
                        Prix (€ / portion)
                        <input onChange={updateInput} name='prix' type='number' min={0} step='0.01' value={newProduit.prix} required />
                    </label>
                    <label>
                        Statut
                        <input
                            name='statutPublication'
                            onClick={updateInput}
                            type="button"
                            value={newProduit.statutPublication ? 'En ligne' : 'Hors ligne'} />
                    </label>
                    <label>
                        Conseil de dégustation
                        <textarea onChange={updateInput} name='conseilDegustation'value={newProduit.conseilDegustation}></textarea>
                    </label>
                    <label>
                        Conseil de conservation
                        <textarea onChange={updateInput} name='conseilConservation' value={newProduit.conseilConservation} ></textarea>
                    </label>
                    <label>
                        Quantités
                        <div className='add-property'>

                        <input name='quantites'  type='number' />
                        <input type="button" onClick={addValueArray} value='+' />
                        </div>
                    </label>
                    <label>
                        Allergènes
                        <div className='add-property'>

                        <input name='allergenes' type='text' />
                        <input type="button" onClick={addValueArray} value='+' />
                        </div>
                    </label>
                    <div className='aller-quanti'>
                        <h2>Quantités :</h2>
                        <ul>
                            {newProduit.quantiteCondi.length > 0 && newProduit.quantiteCondi.map((value, index) => {
                                return <li key={index}>{'- ' + value + ' ' + newProduit.typeCondi + 's'}</li>
                            })}
                        </ul>
                    </div>
                    <div className='aller-quanti'>
                        <h2>Allergènes :</h2>
                        <ul>
                            {newProduit.allergenes.length > 0 && newProduit.allergenes.map((value, index) => {
                                return <li key={index}>{'- ' + value}</li>
                            })}
                        </ul>
                    </div>
                    <div className='btns'>
                        <button className='valider' type='submit'>{props.modalCreate.produit ? "MODIFIER" : "AJOUTER"}</button>
                        <button className='annuler' onClick={props.close}>ANNULER</button>
                    </div>
                </form>
            </div>
            
        </div>
    );
};

export default ModalCreationProduit;
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Connectivity } from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';

const AddSlide = (props) => {
    
    const { user } = useContext(Data);

    const formulaire = useRef(null)
    const inputFile = useRef(null);

    const [file, setFile] = useState(null);
    const [previewsUrl, setPreviewsUrl] = useState(null);


    const sendImage = (form) => {
        const formData = new FormData(form.current);

        Connectivity.addImageToCarrousel(formData, user.token)
            .then(async (response) => {
                const result = await response.json();
                if (response.status === 200) {
                    props.setSlides(result.carrousel.slides)
                    setFile(null);
                    setPreviewsUrl(null);
                }

            })
    }


    useEffect(() => {
        if (!file) return
        const objURL = URL.createObjectURL(file);
        setPreviewsUrl(objURL)
    }, [file])


    return (
        <article className={props.styles.formImage}>
                    
                    <form 
                        ref={formulaire} 
                        className={props.styles.addPreviewImages}
                        onSubmit={(e)=>{e.preventDefault(); sendImage(formulaire)}}
                        encType="multipart/form-data">

                        {!previewsUrl && 
                            <div 
                                className={props.styles.addImage} 
                                onClick={() => inputFile.current.click()} 
                            ></div>}
                        
                        {previewsUrl && 
                            <div className='preview-images'>
                                <img src={previewsUrl} alt="Preview" />
                            </div>}

                        <input
                            hidden
                            ref={inputFile}
                            type='file'
                            name='slide'
                            onChange={(event) => setFile(event.target.files[0])}
                            accept="image/*" />

                        {previewsUrl && <button className={props.styles.valider} type='submit'>AJOUTER UNE SLIDE</button>}
                    </form>
                </article>
    );
};

export default AddSlide;
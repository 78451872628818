import React from 'react';

const Conditionnement = (props) => {
    return (
        <div className='stack introduction'>
            <h2>Conditionnement</h2>
            <p>Choisissez un type de conditionnement</p>
            <div className='stack centered'>
                <div
                    className={`chip${props.conditionnement.type === 'packOfUnit' ? ' selected' : ''}`}
                    name={'conditionnement'}
                    onClick={() => props.updateWhileSelecting('conditionnement', 'packOfUnit')}
                >Pack de ... unité(s)</div>

                <div className='divider'>
                    <hr />
                    <p>OU</p>
                </div>

                <div
                    className={`chip${props.conditionnement.type === 'unit' ? ' selected' : ''}`}
                    name={'conditionnement'}
                    onClick={() => props.updateWhileSelecting('conditionnement', 'unit')}
                >... unité(s)</div>
            </div>

            <div className='stack'>
                <p>{props.conditionnement.type ? 'Remplacez par les termes correspondants' : ''}</p>
                {props.conditionnement.type === 'packOfUnit' &&
                    <div className='flexBetween'>
                        <label>
                            <span>Singulier :</span>
                            <input
                                type='text'
                                name='pack.singular'
                                placeholder="Plateau de"
                                value={props.conditionnement?.pack?.singular}
                                onChange={(e) => props.updateWhileSelecting(e.target.name, e.target.value)}
                            />
                        </label>

                        <label>
                            <span>Pluriel :</span>
                            <input
                                type='text'
                                name='pack.plural'
                                placeholder="Plateaux de"
                                value={props.conditionnement?.pack?.plural}
                                onChange={(e) => props.updateWhileSelecting(e.target.name, e.target.value)}
                            />
                        </label>
                    </div>
                }
                {props.conditionnement.type &&
                    <div className='flexBetween'>
                        <label>
                            <span>Singulier :</span>
                            <input
                                type='text'
                                name='unit.singular'
                                placeholder="Unité"
                                value={props.conditionnement?.unit?.singular}
                                onChange={(e) => props.updateWhileSelecting(e.target.name, e.target.value)}
                            />
                        </label>
                        <label>
                            <span>Pluriel :</span>
                            <input
                                type='text'
                                name='unit.plural'
                                placeholder="Unités"
                                value={props.conditionnement?.unit?.plural}
                                onChange={(e) => props.updateWhileSelecting(e.target.name, e.target.value)}
                            />
                        </label>
                    </div>
                }
            </div>
        </div>
    );
};

export default Conditionnement;
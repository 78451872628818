import React from 'react';

const MenuBurgerIcon = (props) => {
    return (
        <svg 
            width="40" 
            height="40" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
<path 
    d="M4 6H21M4 18H21" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"/>
<path 
d="M4.42445 14.0905C4.32845 14.0905 4.24745 14.0575 4.18145 13.9915C4.12145 13.9195 4.09745 13.8355 4.10945 13.7395L4.49645 10.7155C4.50845 10.5955 4.55345 10.5055 4.63145 10.4455C4.71545 10.3855 4.80545 10.3555 4.90145 10.3555C4.99745 10.3555 5.08445 10.3795 5.16245 10.4275C5.24645 10.4695 5.30345 10.5355 5.33345 10.6255L6.04445 12.6415C6.07445 12.7135 6.10445 12.7495 6.13445 12.7495C6.17045 12.7495 6.20045 12.7135 6.22445 12.6415L6.93545 10.6255C6.96545 10.5355 7.01945 10.4695 7.09745 10.4275C7.18145 10.3795 7.26845 10.3555 7.35845 10.3555C7.45445 10.3555 7.54445 10.3855 7.62845 10.4455C7.71245 10.5055 7.76045 10.5955 7.77245 10.7155L8.15945 13.7395C8.17145 13.8355 8.14745 13.9195 8.08745 13.9915C8.02745 14.0575 7.94645 14.0905 7.84445 14.0905C7.76645 14.0905 7.69445 14.0635 7.62845 14.0095C7.56845 13.9555 7.53245 13.8865 7.52045 13.8025L7.32245 11.5795C7.31645 11.5075 7.29245 11.4715 7.25045 11.4715C7.20845 11.4715 7.17845 11.5015 7.16045 11.5615L6.51245 13.3525C6.47645 13.4485 6.42545 13.5175 6.35945 13.5595C6.29345 13.6015 6.21845 13.6225 6.13445 13.6225C5.97245 13.6225 5.84945 13.5325 5.76545 13.3525L5.10845 11.5615C5.08445 11.5015 5.05445 11.4715 5.01845 11.4715C4.99445 11.4715 4.97645 11.5075 4.96445 11.5795L4.74845 13.8025C4.74245 13.8865 4.70645 13.9555 4.64045 14.0095C4.58045 14.0635 4.50845 14.0905 4.42445 14.0905Z" 
fill="currentColor"/>
<path 
d="M10.0473 14.0005C9.89734 14.0005 9.77734 13.9555 9.68734 13.8655C9.59734 13.7755 9.55234 13.6555 9.55234 13.5055V10.8685C9.55234 10.7305 9.59734 10.6195 9.68734 10.5355C9.78334 10.4455 9.89434 10.4005 10.0203 10.4005H11.3793C11.4813 10.4005 11.5533 10.4275 11.5953 10.4815C11.6433 10.5295 11.6673 10.5925 11.6673 10.6705C11.6673 10.7425 11.6403 10.8055 11.5863 10.8595C11.5383 10.9135 11.4693 10.9405 11.3793 10.9405H10.4163C10.2603 10.9405 10.1823 11.0185 10.1823 11.1745V11.6695C10.1823 11.8255 10.2543 11.9035 10.3983 11.9035H11.2893C11.3913 11.9035 11.4633 11.9305 11.5053 11.9845C11.5533 12.0385 11.5773 12.1015 11.5773 12.1735C11.5773 12.2395 11.5503 12.3025 11.4963 12.3625C11.4483 12.4165 11.3793 12.4435 11.2893 12.4435H10.3983C10.2543 12.4435 10.1823 12.5185 10.1823 12.6685V13.2175C10.1823 13.3735 10.2573 13.4515 10.4073 13.4515H11.4693C11.5713 13.4515 11.6433 13.4815 11.6853 13.5415C11.7333 13.5955 11.7573 13.6585 11.7573 13.7305C11.7573 13.8025 11.7303 13.8655 11.6763 13.9195C11.6283 13.9735 11.5593 14.0005 11.4693 14.0005H10.0473Z" 
fill="currentColor"/>
<path 
d="M13.4701 14.0905C13.3921 14.0905 13.3201 14.0665 13.2541 14.0185C13.1881 13.9705 13.1551 13.8985 13.1551 13.8025V10.7695C13.1551 10.6315 13.1971 10.5295 13.2811 10.4635C13.3711 10.3915 13.4671 10.3555 13.5691 10.3555C13.7251 10.3555 13.8391 10.4155 13.9111 10.5355L15.3961 12.8665C15.4261 12.9145 15.4591 12.9385 15.4951 12.9385C15.5491 12.9385 15.5761 12.8965 15.5761 12.8125L15.5401 10.6435C15.5401 10.5475 15.5731 10.4755 15.6391 10.4275C15.7051 10.3795 15.7771 10.3555 15.8551 10.3555C15.9331 10.3555 16.0051 10.3795 16.0711 10.4275C16.1371 10.4755 16.1701 10.5475 16.1701 10.6435V13.6945C16.1701 13.8265 16.1311 13.9255 16.0531 13.9915C15.9751 14.0575 15.8851 14.0905 15.7831 14.0905C15.7111 14.0905 15.6391 14.0725 15.5671 14.0365C15.5011 14.0065 15.4441 13.9555 15.3961 13.8835L13.9381 11.6155C13.9081 11.5675 13.8751 11.5435 13.8391 11.5435C13.7851 11.5435 13.7581 11.5855 13.7581 11.6695L13.7851 13.8025C13.7851 13.8985 13.7521 13.9705 13.6861 14.0185C13.6261 14.0665 13.5541 14.0905 13.4701 14.0905Z" 
fill="currentColor"/>
<path 
d="M20.1374 10.6525C20.1374 10.5565 20.1704 10.4845 20.2364 10.4365C20.3024 10.3825 20.3744 10.3555 20.4524 10.3555C20.5304 10.3555 20.5994 10.3795 20.6594 10.4275C20.7254 10.4755 20.7584 10.5505 20.7584 10.6525V12.6235C20.7584 12.9775 20.6834 13.2625 20.5334 13.4785C20.3834 13.6945 20.1914 13.8505 19.9574 13.9465C19.7234 14.0425 19.4774 14.0905 19.2194 14.0905C18.9314 14.0905 18.6704 14.0425 18.4364 13.9465C18.2084 13.8505 18.0284 13.6945 17.8964 13.4785C17.7644 13.2565 17.6984 12.9685 17.6984 12.6145V10.6525C17.6984 10.5505 17.7314 10.4785 17.7974 10.4365C17.8634 10.3885 17.9354 10.3645 18.0134 10.3645C18.0914 10.3645 18.1604 10.3885 18.2204 10.4365C18.2864 10.4785 18.3194 10.5505 18.3194 10.6525V12.6145C18.3194 12.9325 18.3974 13.1665 18.5534 13.3165C18.7154 13.4605 18.9374 13.5325 19.2194 13.5325C19.4714 13.5325 19.6874 13.4605 19.8674 13.3165C20.0474 13.1665 20.1374 12.9355 20.1374 12.6235V10.6525Z" 
fill="currentColor"/>
</svg>

    );
};

export default MenuBurgerIcon;
import React, { useContext, useEffect, useState } from 'react';
import {Connectivity} from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';
import AlertModal from '../../modals/AlertModal';
import ItemCommandeAdmin from '../page-commandes/ItemCommandeAdmin';
import ModalDetailsCommande from '../page-commandes/ModalDetailsCommande';
import { useLocation, useNavigate } from 'react-router-dom';


const CommandesAdmin = () => {

    const { user } = useContext(Data);
    const location = useLocation();
    const navigate = useNavigate();

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [commandes, setCommandes] = useState([]);
    const [commandeToDelete, setCommandeToDelete] = useState({
        commandeId : null,
        clientId: null
    });
    const [modalDetails, setModalDetails] = useState({
        open: false
    })

    const alertDeleteCommande = (commande) => {
        setShowModalDelete(true);
        setCommandeToDelete({
            commandeId: commande._id,
            clientId: commande.client.id
        });
    }

    const deleteCommande = () => {
        Connectivity.deleteCommande(commandeToDelete, user.token)
           .then(res => {
               const newArray = commandes;
               const indexDeleted = commandes.findIndex(commande => commande._id === commandeToDelete.commandeId)
               newArray.splice(indexDeleted, 1);
               setCommandes(newArray);
               setShowModalDelete(false);
           })
    }


    useEffect(() => {
       if (user.token){
            /** Fetch all produits */
        Connectivity.getAllCommandes(user.token)
            .then(async (response) => {
                if (response.status === 200) {
                    const result = await response.json();
                    setCommandes(result.commandes)
                }
            })
        }
    }, [user])


    useEffect(()=>{
        const userToken = localStorage.getItem('token');

        if (userToken && location.search){
            
            const token = location.search.split('token=')[1];
            if (!token) return
            if(token && token.length > 0){
                Connectivity.updateStatutCommandeFromEmail(userToken, token)
                .then(async response => {
                    const result = await response.json();
                    if (response.status === 200){
                        navigate('/admin/commandes')
                    }
                })
            }
            
        }
    },[])






    return (
        <>
        <section className='commandes-admin'>
            <h2>COMMANDES</h2>
            <article className='header-commandes'>
                <h3 className='date'>Date retrait</h3>
                <h3 className='date'>Date commande</h3>
                <h3 className='clientName'>Client</h3>
                <h3 className='details'>Détails</h3>
                <h3 className='montant'>Montant</h3>
                <h3 className='statut'>Statut</h3>
            </article>
            <article className='list-commandes'>
            {commandes.length > 0 && commandes.map((commande, index) => {
                const details = []
                commande.panier.forEach(item => {
                    let formatted;
                    if (item.produitCondi?.type === 'unit'){
                        formatted = `${item.produitQuantiteAchat}x ${item.produitTitre} -  ${item.produitQuantiteCondi} ${parseInt(item.produitQuantiteCondi) > 1 ? item.produitCondi?.unit?.plural : item.produitCondi?.unit?.singular}`;
                    }
                    if (item.produitCondi?.type === 'packOfUnit'){
                        formatted = `${item.produitQuantiteAchat}x ${item.produitTitre} - ${parseInt(item.produitQuantiteAchat) > 1 ? item.produitCondi?.pack?.plural : item.produitCondi?.pack?.singular } ${item.produitQuantiteCondi} ${parseInt(item.produitQuantiteCondi) > 1 ? item.produitCondi?.unit?.plural : item.produitCondi?.unit?.singular}`;
                    }
                    details.push(formatted);
                });

                return <ItemCommandeAdmin 
                    key={index}
                    odd={index % 2 === 0 ? true : false}
                    retraitDate={commande.retraitDate}
                    retraitHoraire={commande.retraitHoraire}
                    creationDate={commande.creationDate}
                    clientName={`${commande.client.prenom} ${commande.client.nom}`}
                    montantTotal={commande.montantTotal}
                    validation={commande.validation}
                    details={details}
                    image={commande.panier[0].produitImage}
                    delete={() => alertDeleteCommande(commande)}
                    showDetails={() => setModalDetails({open:true, ...commande})}
                    />
                })}
            {commandes.length === 0 && <p>Pas de commandes</p>}
                </article>

        </section>
        {showModalDelete && <AlertModal action={deleteCommande} close={() => setShowModalDelete(false)} />}
        {modalDetails.open && <ModalDetailsCommande
                                    id={modalDetails._id}
                                    fullName={modalDetails.client.prenom + ' ' + modalDetails.client.nom}
                                    adresse={modalDetails.client.adresse}
                                    email={modalDetails.client.email}
                                    telephone={modalDetails.client.telephone}
                                    creationDate={new Date(modalDetails.creationDate)}
                                    retraitDate={new Date(modalDetails.retraitDate)}
                                    retraitHoraire={modalDetails.retraitHoraire}
                                    validation={modalDetails.validation}
                                    validationPaiementDate={modalDetails.validationPaiementDate}
                                    panier={modalDetails.panier}
                                    montantTotal={modalDetails.montantTotal}
                                    setModalDetails={setModalDetails}
                                    modalDetails={modalDetails}
                                    setCommandes={setCommandes}
                                    commandes={commandes}
                                    close={()=> setModalDetails({open:false})}
         />}
    </>
    );
};

export default CommandesAdmin;
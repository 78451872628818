import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from '../../../assets/scss/admin/CarrouselAdmin.module.scss';
import { Connectivity } from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';
import AlertModal from '../../modals/AlertModal';
import AddSlide from '../page-carrousel/AddSlide';
import GalerieCarrousel from '../page-carrousel/GalerieCarrousel';


const CarrouselAdmin = () => {

    const { user } = useContext(Data);

    const [slides, setSlides] = useState([]);

  

    const [modalDelete, setModalDelete] = useState({
        open: false,
        image: null
    });


   

    const alertDeleteImage = (imageUrl) => {
        setModalDelete({
            open: true,
            image: imageUrl
        });
    }

    const deleteImage = () => {
        Connectivity.deleteImageInCarrousel(modalDelete.image, user.token)
        .then(async (response) => {
            const result = await response.json();
            if (response.status === 200) {
                const index = slides.indexOf(modalDelete.image);
                const newSlides = [...slides];
                newSlides.splice(index, 1);
                setSlides([...newSlides]);
                setModalDelete({
                    open: false,
                    image: null
                });
            }

        })
    }

    useEffect(() => {
        Connectivity.getCarrousel()
        .then(async (response) => {
            const result = await response.json();
            if (response.status === 200) {
                setSlides(result.slides[0].slides)
            }

        })
    },[])

  

    return (
        <>
            <section className={styles.carrouselAdmin}>
                <h2>CARROUSEL</h2>
                {slides.length > 0 && <GalerieCarrousel slides={slides} deleteImage={alertDeleteImage} />}
                <AddSlide setSlides={setSlides} styles={styles} />
            </section>

            {modalDelete.open && <AlertModal 
                                    action={deleteImage} 
                                    close={() => setModalDelete({open: false, image: null})} />}
        </>
    );
};

export default CarrouselAdmin;
import React, { useContext, useEffect, useState } from 'react';
import { Connectivity } from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';

const Allergenes = (props) => {
    const { user } = useContext(Data);

    const [allergenes, setAllergenes] = useState([{nom: 'produit laitier'}, {nom: 'gluten'}, {nom: 'oeuf'}, {nom: 'arachide'}, {nom: 'soja'}, {nom: 'fruits à coque'}, {nom: 'poisson'}, {nom: 'crustacé'}, {nom: 'moutarde'}, {nom: 'céleri'}, {nom: 'lupin'}, {nom: 'anhydride sulfureux et sulfites'}]);
    const [newAllergene, setNewAllergene] = useState({nom: ''});
    const [deleteMode, setDeleteMode] = useState(false);


    /**Create and add new allergene to list */
    const createAllergene = () => {
        if (newAllergene.nom.length < 3) return
        Connectivity.createAllergene(newAllergene, user.token)
            .then(async response => {
                const resJson = await response.json();
                if (response.status === 201) {
                    const allergeneCreated = {_id: resJson.allergeneId, nom: newAllergene.nom}
                    const newAllergenes = [...allergenes];
                    newAllergenes.push(allergeneCreated);
                    setAllergenes(newAllergenes);
                    setNewAllergene({ nom: '' });
                }
            })
    }

    /**Delete allergene from list and DB */
    const deleteAllergene = (e,allergene) => {
        e.preventDefault();
        Connectivity.deleteAllergeneById(user.token, allergene._id)
            .then( async response => {
                const result = await response.json();
                if (response.status === 200) {
                    const indexAllergene = allergenes.indexOf(allergene.nom);
                    const newAllergenes = [...allergenes];
                    newAllergenes.splice(indexAllergene, 1);
                    setAllergenes(newAllergenes);
                    if (props.allergenes.includes(allergene)) props.updateWhileSelecting('allergenes', allergene.nom)
                }
                
            })
    }

    const toggleDeleteMode = () => {
        setDeleteMode(!deleteMode);
    }

useEffect(()=>{
    /** Fetch all allergenes from DB */
    Connectivity.getAllAllergenes()
        .then(async (response) => {
            const result = await response.json();
            if (response.status === 200) {
                setAllergenes(result.allergenes)
            }
            
        })

},[])
    return (
        <div className='stack introduction'>
            <h2>Allergènes</h2>
            <p>Indiquez les allergènes suceptibles d'être présent dans votre produit.</p>
            <button 
                onClick={toggleDeleteMode}
                className='buttonDelete'
                >                
                {!deleteMode ? 'Supprimer' : "Arrêter"}
            </button>
            <div className={props.errors?.includes('allergenes') ?  'flexContainer error' : 'flexContainer'}>
                {allergenes.map((allergene, index) => {
                    return <div 
                            className={`chip${props.allergenes.includes(allergene.nom) ? ' selected' : ''}`} 
                            key={index}
                            name={'allergenes'}
                            onClick={()=>props.updateWhileSelecting('allergenes', allergene.nom)}
                            >{allergene.nom}{deleteMode && <div className='iconDelete' onClick={(e)=>deleteAllergene(e, allergene)}></div>}</div>
                })
            }
            </div>
            <p>Vous pouvez aussi en ajouter un nouveau dans la liste.</p>
            <input 
                type={'text'} 
                value={newAllergene.nom}
                placeholder={'Eau de vie'}
                onChange={e => setNewAllergene({ nom: e.target.value })} />
                    <button 
                    disabled={newAllergene.nom.length < 2}
                    onClick={createAllergene}>CREER ALLERGENE</button>
        </div>
    )
};

export default Allergenes;
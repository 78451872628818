import React, { useEffect } from 'react';
import {Connectivity} from '../../middlewares/Connectivity';
import AProposCompact from '../page-accueil/AProposCompact';
import Bandeau from '../page-accueil/Bandeau';
import Slider from '../page-accueil/Slider';

const Accueil = (props) => {

    useEffect(()=>{
        Connectivity.getAvailableProduits()
            .then(async (response)=>{
                const json = await response.json();
                if (response.status === 200) {
                    props.setListProduits(json.produits)
                }
            })

    },[])

    return (
            <main>
                <Slider />
                <Bandeau />
                <AProposCompact />
               
            </main>
    );
};

export default Accueil;
import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Data from '../../context/DataContext';

const MenuAdmin = () => {
    const { user } = useContext(Data);



    return (
        <nav className='nav-admin'>
            <div className='logo-accueil' >
                <img
                    src={process.env.PUBLIC_URL + "/images/logo/ML-logo_quadri_ssfd.png"}
                    alt="logo" />
            </div>
            <h3>BIENVENUE {user.prenom && user.prenom.toUpperCase()}</h3>
            <Link to='/'>VOIR LE SITE</Link>
        </nav>
    );
};

export default MenuAdmin;
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Admin from '../../context/AdminContext';

const AsideMenuAdmin = () => {
    const {conversations} = useContext(Admin);

    const [newMessages, setNewMessages] = useState(0)

    useEffect(()=>{
        let count = 0;
        if (conversations.length >0){

            conversations.forEach(conv => {
                if(!conv.statutRead) {
                    count++;
                }
            });
        }
        setNewMessages(count);
    },[conversations])

    return (
       <aside className='aside-admin'>
           <ul>
               <li><Link to='/admin'>ACCUEIL</Link></li>
               <li><Link to='/admin/commandes'>COMMANDES</Link></li>
               <li><Link to='/admin/produits'>PRODUITS</Link></li>
               <li><Link to='/admin/clients'>CLIENTS</Link></li>
               <li><Link to='/admin/carrousel'>CARROUSEL</Link></li>
               <li>
                    <Link to='/admin/messages'>MESSAGES</Link>
                    <div className='numberMessages'>{newMessages}</div>
                </li>
               {/* <li><Link to='/admin/apropos'>A PROPOS</Link></li> */}
           </ul>
       </aside>
    );
};

export default AsideMenuAdmin;
import React from 'react';
import settings from '../../../config/settings.json';
import styles from '../../../assets/scss/admin/GalerieCarrouselAdmin.module.scss';

const GalerieCarrousel = (props) => {

    return (
        <article className={styles.galerie}>
            <div key={0} className={styles.fullSlide}>
                <img src={settings.imgSlider + props.slides[0]} alt={'news1'} />
                <p>Image 1</p>
                <div 
                    className={styles.iconDelete}
                    onClick={() => props.deleteImage(props.slides[0])}
                ></div>
            </div>

            <div className={styles.wrapper}>

                {props.slides.map((slide, index) => {
                    if (index === 0) return;
                    return (
                        <div key={index} className={index !== 0 ? styles.slide : styles.fullSlide}>
                            <img src={settings.imgSlider + slide} alt={'news' + (index + 1)} />
                            <p>{'Image ' + (index + 1)}</p>
                            <div 
                                className={styles.iconDelete}
                                onClick={() => props.deleteImage(slide)}
                            ></div>

                        </div>
                    )
                })}
                
            </div>
            {props.slides.length < 1 && <p>Carrousel vide</p>}
        </article>
    );
};

export default GalerieCarrousel;
import React, { useEffect, useState } from 'react';
import {ClickAwayListener} from '@mui/material';
import { Link } from 'react-router-dom';
const settings = require('../../config/settings.json');


const PanierModal = (props) => {
    const [styleTransition, setStyleTransition] = useState({});


    const closeModal = (e) => {
        e.preventDefault();
        setStyleTransition({
            transform: 'translate(100%, 0)'
        })
        setTimeout(()=>props.setShowPanier(false), 950)
    }


    /**
     * 
     * Function to add quantity to selected product in panier 
     */
    const addQuantite = (e) => {
        e.preventDefault();
        if (props.panier[parseInt(e.target.name)].produitQuantiteAchat > 3) return
        const newPanier = [...props.panier];
        const produit = newPanier[parseInt(e.target.name)]
        produit.produitQuantiteAchat++
        produit.produitMontantTotal = produit.produitQuantiteAchat * produit.produitQuantiteCondi * produit.produitPrix
        props.setPanier([...newPanier]);
    }

    /**
     * 
     * Function to decrease quantity to selected product in panier 
     */
    const decreaseQuantite = (e) => {
        e.preventDefault();
        const newPanier = [...props.panier];
        if (props.panier[parseInt(e.target.name)].produitQuantiteAchat < 2) return
        const produit = newPanier[parseInt(e.target.name)];
        produit.produitQuantiteAchat--;
        produit.produitMontantTotal = produit.produitQuantiteAchat * produit.produitQuantiteCondi * produit.produitPrix
        props.setPanier([...newPanier]);
    }

    /**
     * Function to delete item from panier
     */
    const deleteProduit = (index) => {
        const newPanier = [...props.panier];
        newPanier.splice(parseInt(index), 1);
        props.setPanier([...newPanier]);
    }


    return (
        <div className="fondPanier">
            <ClickAwayListener onClickAway={closeModal}>

            <div className="modalPanier" style={styleTransition}>
                <div className='arrow-right' onClick={closeModal}></div>

                <h2>PANIER</h2>
                <article>

                {props.panier.length === 0 && <h2 >Votre panier est vide</h2>}

                {props.panier.length > 0 && props.panier.map((produit, index) => {
                    
                    return (<div key={index} className="item_panier">
                        <div className='image'>
                            <img src={settings.imgUrl + produit.produitImage} alt="" />
                        </div>
                        <div className='infos-produit'>
                            <p className='montant-produit'>{produit.produitMontantTotal.toFixed(2)} €</p>
                            {produit.produitTitre.length> 24 ?
                                <p className='titre'>{produit.produitTitre.slice(0, 24)}...</p> :
                                <p className='titre'>{produit.produitTitre}</p>
                                
                            }
                            {produit.produitCondi?.type === 'unit' && <p>{produit.produitQuantiteCondi} {produit.produitQuantiteCondi > 1 ? produit.produitCondi?.unit?.plural : produit.produitCondi?.unit?.singular}</p>}
                            {produit.produitCondi?.type === 'packOfUnit' && <p>{produit.produitQuantiteAchat > 1 ? produit.produitCondi?.pack?.plural + ' ' : produit.produitCondi?.pack?.singular } {produit.produitQuantiteCondi} {produit.produitQuantiteCondi > 1 ? produit.produitCondi?.unit?.plural : produit.produitCondi?.unit?.singular}</p>}

                            <div className='select-quantity'>
                                <button
                                    name={index}
                                    disabled={props.panier[index].produitQuantiteAchat < 2}
                                    onClick={decreaseQuantite} >-</button>
                                <input readOnly value={produit.produitQuantiteAchat} />
                                <button
                                    onClick={addQuantite}
                                    disabled={props.commande.nbProduits > 3}
                                    name={index}>+</button>
                            </div>
                            <div className='icon-delete' onClick={() => deleteProduit(index)}></div>
                        </div>
                    </div>)
                })}
                </article>


                {props.panier.length > 0 && <>
                    <div className='total-panier'>
                        <h4>TOTAL :</h4>
                        <p>{parseFloat(props.commande.montantTotal).toFixed(2)} €</p>
                    </div>


                    <Link className='checkout' to="/recap" onClick={() => props.setShowPanier(false)}>POURSUIVRE COMMANDE</Link>
                </>}

                    {/* <p>La quantité maximale de 4 gourmandises a été atteinte, pour une commande plus conséquente, veuillez me contacter directement depuis la page Contact</p> */}


            </div>
                </ClickAwayListener>
        </div>
    );
};

export default PanierModal;
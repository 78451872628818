import React, { useContext, useEffect, useState } from 'react';
import { Connectivity } from '../../../middlewares/Connectivity';
import {useLocation, useNavigate } from 'react-router-dom';
import Data from '../../context/DataContext';
import ItemConversation from '../page-messages/ItemConversation';
import styles from '../../../assets/scss/admin/MessagesAdmin.module.scss';
import BoxConversation from '../page-messages/BoxConversation';
import AlertModal from '../../modals/AlertModal';
import Admin from '../../context/AdminContext';


const MessagesAdmin = () => {

    const { user } = useContext(Data);

    const {
        conversations, 
        setConversations, 
        getConversations} = useContext(Admin);

    const navigate = useNavigate();

    const location = useLocation();

    const [selectedConv, setSelectedConv] = useState(null);
    const [modalDelete, setModalDelete] = useState({
        open: false,
        conversation: null
    });

    const deleteConversation = () => {
        Connectivity.deleteConversationById(user.token, modalDelete.conversation._id)
            .then(async response => {
                const result = await response.json();
                if (response.status === 200) {
                    const foundIndex = conversations.indexOf(modalDelete.conversation)
                    const newConversations = [...conversations];
                    newConversations.splice(foundIndex, 1);
                    if (selectedConv === modalDelete.conversation) setSelectedConv(newConversations[0]);
                    setConversations([...newConversations]);
                    setModalDelete({open: false, conversation: null});
                    
                    console.log(result.message);
                }
                console.log(result);

            })
    }

    useEffect(()=> {
        if (user.token){
            getConversations();
        }
    },[user.token])
    
    
    useEffect(()=>{
        if (conversations.length > 0) {
            setSelectedConv(conversations[0])

            const userToken = localStorage.getItem('token');
            
            if (userToken && location.search){
                
                const token = location.search.split('token=')[1];
                if (!token) return navigate('/admin/messages');

                if(token && token.length > 0){
                Connectivity.getConversationFromEmail(token, userToken)
                .then(async response => {
                    const result = await response.json();
                    if (response.status === 200){
                        setSelectedConv(result.conversation)
                    }
                })
            }
            
        }
    }
    },[conversations])
    return (
        <>
            <section className={styles.messagesAdmin}>
                <h2>MESSAGES</h2>
                <article className={styles.indexConversations}>
                    {conversations.length > 0 && conversations.map((conv, index) => {
                      return  <ItemConversation 
                            key={index}
                            statutRead={conv.statutRead}
                            creationDate={new Date(conv.creationDate)}
                            message={conv.messages[0].contenu}
                            nomComplet={`${conv.client.nom} ${conv.client.prenom}`}
                            showConv={()=> setSelectedConv(conv)}
                            delete={()=>setModalDelete({open: true, conversation: conv })}
                             />
                    })
                    }

                </article>
                { selectedConv && <BoxConversation 
                                    convId={selectedConv._id}
                                    email={selectedConv.client.email}
                                    prenom={selectedConv.client.prenom}
                                    nom={selectedConv.client.nom}
                                    telephone={selectedConv.client.telephone}
                                    messages={selectedConv.messages}
                                    selectedConv={selectedConv}
                                    setSelectedConv={setSelectedConv}
                />}
            </section>
            {modalDelete.open && <AlertModal action={deleteConversation} close={() => setModalDelete(false)} />}
        </>
    );
};

export default MessagesAdmin;
import React, { useContext, useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import Admin from '../../context/AdminContext';
import AlertCircleIcon from '../../icons/AlertCircleIcon';
import CheckIcon from '../../icons/CheckIcon';
import LogoFull from '../../icons/LogoFull';
import MenuBurgerIcon from '../../icons/MenuBurgerIcon';

const EditingMenu = (props) => {

    const { editingProduit } = useContext(Admin);

    const verifyStep = () => {
        const errors = [];
        editingProduit.steps.map((step, index) => {
            if (step.required) {
                for (let field of step.fields) {

                    /** Check for images */
                    if (field.required && 
                        field.name === "images" &&
                        props.newProduit.newImages.length === 0 && 
                        props.newProduit.images.length === 0) {

                        return errors.push(step.name)
                    }

                    /** Check for conditionnement type is not empty */
                    if (field.required &&
                        field.name === "conditionnement" &&
                        props.newProduit.conditionnement.type === "") {
                            return errors.push(step.name);
                    }

                    /** Check for conditionnement unit is not empty */
                    if (field.required &&
                        field.name === "conditionnement" &&
                        props.newProduit.conditionnement.type !== "" &&
                        (props.newProduit.conditionnement.unit.singular === "" ||
                        props.newProduit.conditionnement.unit.plural === "")) {
                            return errors.push(step.name);
                    }

                    /** Check for conditionnement type is packOfUnit && pack is not empty */
                    if (field.required &&
                        field.name === "conditionnement" &&
                        props.newProduit.conditionnement.type === "packOfUnit" &&
                        (props.newProduit.conditionnement?.pack?.singular === "" ||
                        props.newProduit.conditionnement?.pack?.plural === "")) {
                            return errors.push(step.name);
                    }

                    /**  Check for every inputs but images */
                    if (field.required && props.newProduit[field.name].length === 0 && field.name !== "images") {
                        return errors.push(step.name);
                    }
                }
            }
        })
        props.setErrors(errors);
    }

    useEffect(() => {
        verifyStep();
    }, [props.stepper])

    useEffect(()=>{
        verifyStep();
    },[props.newProduit.conditionnement])



    return (
        <>
            <aside className={props.openMenu ? 'editingAsideMenu' : 'editingAsideMenu closed'}>
                <LogoFull />
                <MenuBurgerIcon className={'floatMenu'} onClick={props.toggleMenu} />
                <Link to={'/admin/produits'} onClick={props.stopEditing}>Retour à l'administration</Link>
                <ul>
                    {editingProduit?.steps.map((step, index) => {
                        return (
                            <li
                                key={index}
                                className={index === props.stepper ? 'active' : ''}
                                onClick={() => props.setStepper(index)}
                            >
                                <p>{step.name}</p>
                                {props.errors?.includes(step.name) && step.required && <AlertCircleIcon color={'#f5a623'} />}
                                {!props.errors?.includes(step.name) && step.required && <CheckIcon color={'#2ECC71'} />}
                            </li>
                        )
                    })
                    }
                </ul>
            </aside>
        </>
    );
};

export default EditingMenu;
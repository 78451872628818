import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {Connectivity} from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';
import AlertModal from '../../modals/AlertModal';
import ItemProduit from '../page-produits/ItemProduit';
import ModalCreationProduit from '../page-produits/ModalCreationProduit';

const ProduitsAdmin = () => {
    const { user } = useContext(Data);

    const { startEditing, newProduit, setNewProduit } = useOutletContext();

    const navigate = useNavigate();

    const createNewProduit = () => {
        navigate('/admin/nouveau-produit');
    }
    const updateProduit = (produit) => {
        setNewProduit({
            ...newProduit,
            ...produit,
            conditionnement: {
                ...newProduit.conditionnement,
                ...produit.conditionnement
            }
        })
        navigate('/admin/modification-produit');
    }
 
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [produits, setProduits] = useState([]);
    const [produitToDelete, setProduitToDelete] = useState(null);

    const alertDeleteProduit = (produit) => {
        setShowModalDelete(true);
        setProduitToDelete(produit);
    }

    const deleteProduit = () => {
        Connectivity.deleteProduit(produitToDelete._id, user.token)
           .then(res => {
               const indexDeleted = produits.indexOf(produitToDelete)
               const newArray = produits;
               newArray.splice(indexDeleted, 1);
               setProduits(newArray);
               setShowModalDelete(false)
           })
    }

    useEffect(() => {
        if (!user.token) return
        /** Fetch all produits */
        Connectivity.getAllProduits(user.token)
            .then(async (response) => {
                if (response.status === 200) {
                    const result = await response.json();
                    console.log(result);
                    setProduits(result.produits)
                }
            })
    }, [user.token])
    return (
        <>
        <section className='produits-admin'>
            <h2>PRODUITS</h2>
            <button className='create-produit' onClick={createNewProduit}>Ajouter un produit</button>
            <article className='header-produits'>
                <h3 className='date'>Date de création</h3>
                <h3 className='titre'>Titre</h3>
                <h3 className='categorie'>Catégorie</h3>
                <h3 className='description'>Description</h3>
                <h3 className='prix'>Prix</h3>
                <h3 className='condi'>Conditionnement</h3>
                <h3 className='statut'>Statut</h3>
            </article>
            <article className='list-produits'>

            {produits.length > 0 && produits.map((produit, index) => {
                return <ItemProduit
                key={index}
                odd={index % 2 === 0 ? true : false}
                image={produit.images[0]}
                creationDate={produit.creationDate}
                    titre={produit.titre}
                    description={produit.description}
                    categorie={produit.categorie}
                    prix={produit.prix}
                    conditionnement={produit.conditionnement}
                    quantiteCondi={produit.quantiteCondi}
                    statut={produit.statutPublication ? 'En ligne' : 'Hors ligne'}
                    edit={() => updateProduit(produit)}
                    delete={()=>alertDeleteProduit(produit)} />
                })}
            {produits.length === 0 && <p>Pas de produits</p>}
                </article>

        </section>
        {/* {modalCreate.open && <ModalCreationProduit 
                            produits={produits} 
                            setProduits={setProduits} 
                            modalCreate={modalCreate} 
                            close={() => setModalCreate({...modalCreate, open: false, produit: null})} />} */}
        {showModalDelete && <AlertModal action={deleteProduit} close={() => setShowModalDelete(false)} />}
    </>
    );
};

export default ProduitsAdmin;
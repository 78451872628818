import React from 'react';

const Bandeau = () => {
    return (
        <section className="bandeau">
    <article>
        <h4>PAIEMENT AU RETRAIT</h4>
        <p>Espèces, CB, chèques acceptés</p>
        <div className='icon credit-card'></div>
    </article>
    <article>
        <h4>POINT DE RETRAIT</h4>
        <p>Récupérez votre commande sous 72h</p>
        <div className='icon shop'></div>
    </article>
    <article>
        <h4>ME CONTACTER</h4>
        <p>Pour les commandes spéciales, vous pouvez utiliser le formulaire de la page Contact.</p>
    </article>
</section>
    );
};

export default Bandeau;
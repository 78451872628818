import { useContext, useEffect, useState } from "react";
import {Connectivity} from "../../../middlewares/Connectivity";
import Data from "../../context/DataContext";

const ModalInscriptionClient = (props) => {

    const { user } = useContext(Data);

    const [newClient, setNewClient] = useState({
        nom: '',
        prenom: '',
        telephone: '',
        adresse: {
            ligne1: '',
            ligne2: '',
            codePostal: '',
            ville: ''
        },
        email: '',
        password: '',
        cgv: true
    });
    const [dataIsCorrect, setDataIsCorrect] = useState(false);

    /** automatically update state with input */
    const updateInput = (event) => {
        if (event.target.name === "ligne1" || 
            event.target.name === "ligne2" || 
            event.target.name === "codePostal" ||
            event.target.name === "ville"){
            setNewClient({
                ...newClient,
               adresse: {
                   ...newClient.adresse,
                   [event.target.name]: event.target.value
                }
            })            
        } else {

            setNewClient({
                ...newClient,
                [event.target.name]: event.target.value
            })
        }
    }
  

    /** Verify datas are corrects */
    const checkFormAndSend = (e) => {
        e.preventDefault();
        setDataIsCorrect(true);
    }

    useEffect(() => {
        if (dataIsCorrect && !props.editUser) {
            Connectivity.createUser(newClient)
                .then(async (response) => {
                    const result = await response.json();
                    console.log(result);
                    if (response.status === 201) {
                        console.log('ici');
                        const newUsers = props.users;
                        newUsers.unshift(result.user);
                        setDataIsCorrect(false)
                        props.setUsers( newUsers );
                        props.close()
                    } else {
                        setDataIsCorrect(false)
                    }

                })
        }
        if (dataIsCorrect && props.editUser) {
            Connectivity.editUser(newClient, newClient._id, user.token)
            .then(async (response) => {
                const result = await response.json();
                console.log("result => ", result);
                console.log("status => ", response.status);
                if (response.status === 200) {
                    const newUsers = props.users;
                        const found = newUsers.findIndex( client => client._id === result.userUpdated._id);
                        newUsers.splice(found, 1, newClient);
                        setDataIsCorrect(false)
                        props.setUsers( [...newUsers] );
                        props.close();
                    } 
                })
        }
    }, [dataIsCorrect, props.users])

    useEffect(()=>{
        if (props.editUser){
            setNewClient({...props.editUser})
        }
    },[props.editUser])

  


    return (
        <div className='fond-modal'>
            <div className="modal-client">

                <h2>Inscription</h2>
                <form onSubmit={checkFormAndSend}>
                    <label>
                        Nom :
                        <input name="nom" type="text" onChange={updateInput} value={newClient.nom} required />
                    </label>
                    <label>
                        Prénom :
                        <input name="prenom" type="text" onChange={updateInput} value={newClient.prenom} required />
                    </label>
                    <label>
                        Téléphone :
                        <input name="telephone" type="tel" pattern="[0-9]{10}" onChange={updateInput} value={newClient.telephone} required />
                    </label>
                    <label>
                        Adresse :
                        <input name="ligne1" type="text" onChange={updateInput} value={newClient.adresse.ligne1} required />
                    </label>
                    <label>
                        Complément (optionnel) :
                        <input name="ligne2" type="text" onChange={updateInput} value={newClient.adresse.ligne2} />
                    </label>
                    <div>

                        <label>
                            Code Postal :
                            <input name="codePostal" type="text" onChange={updateInput} value={newClient.adresse.codePostal} required />
                        </label>
                        <label>
                            Ville :
                            <input name="ville" type="text" onChange={updateInput} value={newClient.adresse.ville} required />
                        </label>
                    </div>
                    <label>
                        Email :
                        <input name="email" type="text" autoComplete="off" onChange={updateInput} value={newClient.email} required />
                    </label>
                    <label>
                        Mot de passe :
                        <input name="password" type="password" autoComplete="off" onChange={updateInput} value={newClient.password} required />
                    </label>
                    <div className="buttons">
                        <button className="valider" type="submit">{props.editUser ? "EDITER" : "VALIDER"}</button>
                        <button className="annuler" onClick={props.close}>ANNULER</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModalInscriptionClient;
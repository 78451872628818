import React from 'react';
const settings = require('../../config/settings.json')

const ItemCommande = (props) => {
    return (
        <div className='item-carte-commande'>
            <div className='image'>
                <img src={settings.imgMiniUrl + (props.image ? props.image : 'default.png')} />
            </div>
            <div className='details'>
                <p className='date'>{props.creationDate.slice(0, 10)}</p>
                <p className='montant'>{parseFloat(props.montantTotal).toFixed(2) + ' €'}</p>
                {props.details.map((produit, index) => {
                    return <p key={index}>{produit}</p>
                })}
            <p className='statut'>{props.statut}</p>
            </div>
        </div>
    );
};

export default ItemCommande;
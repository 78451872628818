import React from 'react';
const settings = require('../../../config/settings.json');

const ItemProduit = (props) => {
    const formattedDescription = () => {
        if (props.description.length > 30){
            const description = props.description.slice(0, 50) + '...';
            return description
        }
        return props.description
    }
    return (
        <article className={!props.odd ? 'item-produit' : 'item-produit darker'}>
            <div className='image'>
            <img src={settings.imgMiniUrl + props.image} />

            </div>
            <p className='date'>{new Date(props.creationDate).toLocaleString().slice(0, 10)}</p>
            <p className='titre'>{props.titre}</p>
            <p className='categorie'>{props.categorie}</p>
            <p className='description'>{formattedDescription()}</p>
            <p className='prix'>{props.prix + ' € / ' + props.conditionnement?.unit?.singular}</p>
            <p className='condi'>{props.quantiteCondi.join (', ')}</p>
            <p className='statut'>{props.statut}</p>
            <div className='actions'>
                <div className='admin-icon-edit' onClick={props.edit}></div>
                <div className='icon-delete' onClick={props.delete}></div>
            </div>
        </article>
    );
};

export default ItemProduit;
import React, { useContext, useEffect, useState } from 'react';
import styles from '../../../assets/scss/admin/BoxConversationAdmin.module.scss';
import { Connectivity } from '../../../middlewares/Connectivity';
import Data from '../../context/DataContext';


const BoxConversation = (props) => {
    const { user } = useContext(Data);
    const [messageToSend, setMessageToSend] = useState ({
        userId: null,
        nomComplet: null,
        contenu: ''
    })
    const sendMessage = () => {
        Connectivity.updateConversationById(messageToSend, user.token, props.convId)
            .then(async response => {
                const resJson = await response.json();
                if (response.status === 200) {
                    const newMessages = [...props.selectedConv.messages];
                    newMessages.unshift(messageToSend);
                    props.setSelectedConv({... props.selectedConv, messages: newMessages});
                    setMessageToSend({...messageToSend, contenu: ''})
                }
            })
    }

    useEffect(()=>{
        if (user.role !== 'anonymous') {
            setMessageToSend({
                ...messageToSend,
                userId: user._id,
                nomComplet: `${user.prenom} ${user.nom}`
            })
        }
    },[user])

    return (
        <article className={styles.boxConversation}>
            <div className={styles.headerConv}>
                <div className={styles.infos}>
                    <p>Email: {props.email}</p>
                    <p>Téléphone: {props.telephone}</p>
                </div>
                <h2>{`${props.prenom} ${props.nom}`}</h2>
                <button className={styles.buttonCloture}>Clôturer la conversation</button>
            </div>
            <div className={styles.listMessages}>
                {props.messages.map((message, index) => {
                    return <p 
                            key={index} 
                            className={message.userId === user._id ? styles.rightMessage : styles.leftMessage}
                            >{message.contenu}</p>
                })}
            </div>
            
            <div className={styles.response}>
                <textarea onChange={(e)=>setMessageToSend({...messageToSend, contenu: e.target.value})} value={messageToSend.contenu}></textarea>
                <button onClick={sendMessage}>Envoyer</button>
            </div>
        </article>
    );
};

export default BoxConversation;